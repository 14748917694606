import React, { useState } from 'react';
import { useInfiniteHistoryList } from '../../apis/PayApi';
import { Button, Table } from '../../components';
import { displayDate } from '../../utils/helpers';
import { useNavigate } from 'react-router-dom';

const hislbl = {
    upcom: "Upcoming",
    compl: "Completed",
};

const History = () => {

    const navigate = useNavigate();

    const historyKey = [
        { label: hislbl.upcom, key: "ongoing" },
        { label: hislbl.compl, key: "completed" }
    ];

    const [activeHis, setActiveHis] = useState(historyKey[0]);

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
    } = useInfiniteHistoryList(activeHis.key);

    const handleActiveHis = (obj) => {
        setActiveHis(obj);
    };


    const tickteTableBody = data?.pages?.flatMap(page =>
        page.data.map((val) => [
            val.id,
            <Button
                key={val.key}
                onClick={() => navigate(`/history-detail?id=${val.id}`)}
                className={"text-blue-600 whitespace-nowrap"}
                label={val?.booking_info?.event_info?.title}
            />,
            val?.booking_info?.date ? displayDate(val?.booking_info?.date) : null,
            val?.booking_info?.total_ticket,
            <a
                className='hover:text-primary'
                target='_blank'
                rel='noopener noreferrer'
                href={val?.booking_info?.event_info?.download_url}
            >Download
            </a>,
        ])
    );


    const skeletonRows = Array(5).fill(0).map((_, index) => (
        <div key={index} className="flex space-x-4 animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-full"></div>
        </div>
    ));

    return (
        <div className='edge-spacing bg-[#E0ECF9] py-12'>
            <div className='p-4 md:p-8 lg:p-12 space-y-4 bg-white md:w-[85%] m-auto rounded-md shadow-[0_0_10px_rgba(0,0,0,0.1)]'>
                <div className='flex justify-between items-center gap-4 border-b'>
                    <div className='font-medium text-2xl py-3'>Booked Event History</div>
                    <div>
                        {historyKey.map(val => (
                            <Button
                                key={val.key}
                                onClick={() => handleActiveHis(val)}
                                className={`${activeHis.label === val.label ? "border-primary" : "border-gray-100"} border-b-2 text-sm font-medium text-black px-6 py-2`}
                                label={val.label}
                            />
                        ))}
                    </div>
                </div>
                <div className='pt-8 space-y-1 mb-4 w-full'>
                    {!data ? (
                        <div className="space-y-4">
                            {skeletonRows}
                        </div>
                    ) : (
                        <Table
                            headItems={["ID", "Event", "Purchased On", "No of Tickets", "Action"]}
                            bodyItems={tickteTableBody}
                            bodyCss={"border-primary"}
                            colWidth={["w-[4%]", "w-[24%]", "w-[24%]", "w-[24%]", "w-[24%]"]}
                        />
                    )}
                </div>

                {hasNextPage && (
                    <div className="flex justify-center mt-4">
                        <Button
                            onClick={() => fetchNextPage()}
                            disabled={!hasNextPage || isFetchingNextPage}
                            className="px-4 py-2 bg-blue-500 text-white rounded"
                        >
                            {isFetchingNextPage
                                ? 'Loading more...'
                                : hasNextPage
                                    ? 'Load More'
                                    : 'Nothing more to load'}
                        </Button>

                        <div>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default History;