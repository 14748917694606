import { useState, useEffect } from 'react';

const useElementSize = (elementRef) => {
    const [elementSize, setElementSize] = useState(null);

    useEffect(() => {
        const getElementHeight = () => {
            if (!elementRef.current) return;
            const { height } = elementRef.current.getBoundingClientRect();
            setElementSize(height);
        };

        getElementHeight();

        const handleResize = () => {
            getElementHeight();
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [elementRef]);

    return elementSize;
};

export default useElementSize;
