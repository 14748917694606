import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { usePassUpdate } from '../../apis/AuthApi'
import { Button, OverlayLoading, PasswordField, TextField } from '../../components'



const RegisterValidationSchema = Yup.object({
    email: Yup.string().email().required("Please enter your email"),
    password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one symbol")
        .required("Please enter your password"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords must match")
        .required("Please confirm your password"),
})


const NewPass = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams()
    const email = searchParams.get('email')


    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);


    const { mutate: passUpdateMutate, isPending: isVerifying } = usePassUpdate();


    const onFormSubmit = (val, action) => {
        passUpdateMutate(val)
        action.resetForm()
    }


    const initialValues = {
        email: email,
        password: "",
        password_confirmation: "",
    }

    return (
        <>
            {isVerifying &&
                <OverlayLoading />
            }

            <div className='bg-[#E5F0FD] py-12 edge-spacing'>

                <Formik
                    initialValues={initialValues}
                    onSubmit={onFormSubmit}
                    validationSchema={RegisterValidationSchema}
                >
                    {() => (
                        <Form className='p-4 md:p-8 lg:p-12 space-y-4 bg-white md:w-[80%] lg:w-1/2 m-auto rounded-md shadow-[0_0_10px_rgba(0,0,0,0.1)]'>

                            <div className='font-medium text-xl'>Enter New Password</div>


                            <TextField
                                label="Email"
                                name="email"
                                type="email"
                                disabled={true}
                                focus={true}
                            />

                            <PasswordField
                                label="Password"
                                name="password"
                                type={`${showPassword ? "text" : "password"}`}
                                setShowPassword={setShowPassword}
                                showPassword={showPassword}
                            />

                            <PasswordField
                                label="Confirm Password *"
                                name="password_confirmation"
                                type={`${showConfirmPassword ? "text" : "password"}`}
                                setShowPassword={setShowConfirmPassword}
                                showPassword={showConfirmPassword}
                            />


                            <div className='text-sm mt-8 space-y-2'>
                                <Button
                                    className='text-sm font-medium rounded-md text-white px-6 py-3 bg-primary'
                                    type='submit'
                                    label={'Submit'}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>
        </>
    )
}

export default NewPass
