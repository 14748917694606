import React from 'react'
import {
  Home,
  ContactUs,
  Festivals,
  LiveConcerts,
  Marketing,
  Movies,
  Others,
  Seminar,
  History,
  PrivacyPolicies,
  TermsAndConditions,
  AboutUs,
  Login,
  Register,
  ResetPassword,
  Concert,
  // BookNow,
  BookingProcess,
  AllEvents,
  GetOtp,
  RegForm,
  PaymentProcess,
  ResetPassOtp,
  NewPass,
  Profile,
  HistoryDetail
} from './pages/index'

import { Route, Routes } from 'react-router-dom'
import {
  Footer,
  Navbar,
  BreadCrumbs,
  Sidebar
} from './components/index'

import SecondFooterLayout from './layout/SecondFooterLayout'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useAxiosInterceptors } from './apis/baseConfig'

const App = () => {

  useAxiosInterceptors();
  const navHeight = useSelector((state) => state.navHeightReducer.height)

  return (
    <div className="flex flex-col min-h-screen text-secondary">

      <Navbar />
      <Sidebar />

      <div style={{ marginTop: navHeight }}>

        <Routes>

          <Route path='/user/login' element={<Login />} />
          <Route path='/user/register' element={<Register />} />
          <Route path='/user/register/get-otp' element={<GetOtp />} />
          <Route path='/user/register/reg-form' element={<RegForm />} />
          <Route path='/user/reset-password' element={<ResetPassword />} />
          <Route path='/password/reset/otp' element={<ResetPassOtp />} />
          <Route path='/password/reset/:id?' element={<NewPass />} />

          <Route element={<BreadCrumbs />}>
            <Route path='/all-events' element={<AllEvents />} />
          </Route>

          <Route element={<SecondFooterLayout />}>
            <Route path='/' element={<Home />} />

            <Route element={<BreadCrumbs />}>
              <Route path='/contact-us' element={<ContactUs />} />
              <Route path='/festivals' element={<Festivals />} />
              <Route path='/live-concerts' element={<LiveConcerts />} />
              <Route path='/marketing' element={<Marketing />} />
              <Route path='/movies' element={<Movies />} />
              <Route path='/others' element={<Others />} />
              <Route path='/seminar' element={<Seminar />} />
              <Route path='/about-us' element={<AboutUs />} />
              <Route path='/concert/:id?' element={<Concert />} />
            </Route>
          </Route>

          <Route path='/history' element={<History />} />
          <Route path='/history-detail' element={<HistoryDetail />} />
          <Route path='/profile' element={<Profile />} />
          {/* <Route path='/event-booking/:id?' element={<BookNow />} /> */}
          <Route path='/booking-process/:id?' element={<BookingProcess />} />
          <Route path='/payment-process/:id?' element={<PaymentProcess />} />

          <Route path='/privacy-policies' element={<PrivacyPolicies />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />

        </Routes>

      </div>
      <div className='mt-auto'>
        <Footer />
      </div>
      <ToastContainer />
    </div>
  )
}

export default App

/**
 * @z_index Navbar = 35;
 * @z_index Navbar_ProfileHover = 40;
 * @z_index Modal = 50;
 * 
 */