import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGetProfile, useUpdateProfile, useUploadImg } from '../../apis/AuthApi';
import { Button } from '../../components';
import ProfileSkeleton from './ProfileSkeleton';
import { MdModeEdit } from "react-icons/md";


const Profile = () => {
    const { data: catList, isLoading } = useGetProfile();
    const info = catList?.user_detail;

    const { mutateAsync: uploadMutate, isPending: isUploading } = useUploadImg();
    const { mutate: updateMutate, isPending: isUpdating } = useUpdateProfile();

    const [initialValues, setInitialValues] = useState({
        name: '',
        email: '',
        contact: '',
        address: '',
        image: null,
    });

    const [imagePreview, setImagePreview] = useState('');

    useEffect(() => {
        if (info) {
            setInitialValues({
                name: info.name || '',
                email: info.email || '',
                contact: info.user_info?.phone || '',
                address: info.user_info?.address || '',
                image: null,
            });
            setImagePreview(info.image_url || '');
        }
    }, [info]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            contact: Yup.string()
                .matches(/^[0-9]{9,11}$/, 'Contact must be between 9 and 11 digits')
                .required('Contact is required'),
            address: Yup.string().required('Address is required'),
        }),
        onSubmit: async (values) => {

            const updateData = {
                name: values.name,
                email: values.email,
                phone: values.contact,
                address: values.address,
            };

            updateMutate(updateData);
        },
    });

    const handleImageChange = (event) => {
        const file = event.currentTarget.files[0];
        if (file) {
            setImagePreview(URL.createObjectURL(file));
            const formData = new FormData();
            formData.append('image', file);
            uploadMutate(formData)
        }
    };

    if (isLoading || !info) {
        return <ProfileSkeleton />;
    }

    return (
        <div className='edge-spacing bg-[#E0ECF9] py-12'>
            <div className='p-4 md:p-8 lg:p-12 space-y-4 bg-white md:w-[85%] m-auto rounded-md shadow-[0_0_10px_rgba(0,0,0,0.1)]'>
                <div className='flex justify-between items-center gap-4 border-b'>
                    <div className='font-medium text-2xl py-3'>Profile</div>
                </div>


                <form onSubmit={formik.handleSubmit} className="space-y-6">
                    <section className='flex flex-wrap md:flex-nowrap gap-3 justify-around md:justify-between items-start'>

                        <div className='lg:px-10'>
                            <div className='group relative mt-7 border w-32 md:w-40 lg:w-60 2xl:w-72 aspect-square rounded-full overflow-hidden'>
                                <label htmlFor="image"
                                    className='group-hover:block hidden z-[5 w-full h-full bg-black opacity-15 rounded-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute duration-300 cursor-pointer'
                                >
                                </label>
                                <label htmlFor="image" className='cursor-pointer top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute'>
                                    <MdModeEdit className='group-hover:block hidden text-white text-4xl duration-300' />
                                    <div className='text-white text-xl  group-hover:block hidden'>Edit</div>
                                </label>
                                <img src={imagePreview} className='w-full h-full object-cover' alt="Profile" />
                            </div>

                            <input
                                id="image"
                                type="file"
                                onChange={handleImageChange}
                                className="hidden"
                            />
                        </div>

                        <div className='w-full md:w-[65%] md:pl-4 lg:p-0 space-y-4'>
                            <div className='max-w-96 mx-auto lg:mr-auto 2xl:m-0'>
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                    Name
                                </label>
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <div className="text-red-500 text-xs italic">{formik.errors.name}</div>
                                ) : null}
                            </div>
                            <div className='max-w-96 mx-auto lg:mr-auto 2xl:m-0'>
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    disabled={true}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="text-red-500 text-xs italic">{formik.errors.email}</div>
                                ) : null}
                            </div>
                            <div className='max-w-96 mx-auto lg:mr-auto 2xl:m-0'>
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contact">
                                    Contact
                                </label>
                                <input
                                    id="contact"
                                    name="contact"
                                    type="number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.contact}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {formik.touched.contact && formik.errors.contact ? (
                                    <div className="text-red-500 text-xs italic">{formik.errors.contact}</div>
                                ) : null}
                            </div>
                            <div className='max-w-96 mx-auto lg:mr-auto 2xl:m-0'>
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
                                    Address
                                </label>
                                <input
                                    id="address"
                                    name="address"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.address}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {formik.touched.address && formik.errors.address ? (
                                    <div className="text-red-500 text-xs italic">{formik.errors.address}</div>
                                ) : null}
                            </div>

                            <div className='max-w-96 mx-auto lg:mr-auto 2xl:m-0 flex justify-end items-center'>
                                <Button
                                    type="submit"
                                    className="mt-2 bg-primary px-4 py-3 text-white rounded"
                                    label="Update Profile"
                                    disabled={isUpdating || isUploading}
                                />
                            </div>
                        </div>
                    </section>
                </form>
            </div>
        </div>
    );
};

export default Profile;
