import React from 'react'
import SupportForm from './SupportForm'

const ContactUs = () => {


    const contactDetails = [
        { id: 1, label: "Address", content: "22 Market street, sydney NSW" },
        { id: 2, label: "Contact", content: "1300287637" },
        { id: 3, label: "Need live support?", content: "info@olaticket.com.au" },
        { id: 4, label: "Detail", content: "Edmonson Park, Sydney" },
    ]

    return (
        <div className='edge-spacing py-12'>

            <div className='flex flex-col lg:flex-row gap-x-3 gap-y-12 justify-center items-start'>
                <div className='w-full lg:w-1/2 p-4 lg:pl-0'>

                    <div className='space-y-6'>

                        <h1 className='title'>Contact Us</h1>

                        <div className='grid gap-x-3 gap-y-6 grid-cols-[repeat(auto-fill,minmax(165px,1fr))] '>
                            {contactDetails?.map((val) => (
                                <div key={val.id} className='space-y-4'>
                                    <div className='text-sm text-customGray'>{val.label}</div>

                                    <div className='font-medium'>{val.content}</div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>

                <div className='w-full lg:w-1/2'>

                    <SupportForm />


                </div>
            </div>
        </div>
    )
}

export default ContactUs