import Cookies from "js-cookie";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


const BASE_URL = 'https://olaticket.com.au/api';


export const axiosInstance = axios.create({
    baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
    config => {
        const token = Cookies.get("access_token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


// {"message":"Invalid access token"}
const handleUnauthorizedError = async (error, navigate) => {
    // if (error.response && error.response.status === 401) {
    if (error.response && error.response.data?.message === "Invalid access token") {
        /**
         * Logout api requires unexpired token! WTF!!!
         */
        // await logOut();
        navigate("/", { replace: true });
        Cookies.remove("access_token")
    }
    return Promise.reject(error);
};


export const useAxiosInterceptors = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const interceptor = axiosInstance.interceptors.response.use(
            response => response,
            (error) => handleUnauthorizedError(error, navigate)
        );

        return () => {
            axiosInstance.interceptors.response.eject(interceptor);
        };
    }, [navigate]);
};

// axiosInstance.interceptors.response.use(
//     response => response,
//     (err) => {
//         const navigate = useNavigate();
//         console.log(err)
//         handleUnauthorizedError(err, navigate)
//     }
// );

export const axiosTokenless = axios.create({
    baseURL: BASE_URL,
});
