import React from 'react'
import { Button, FormikDropdown, FormikTextArea, TextField } from '../../components'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'


const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const supportValidationSchema = Yup.object({
    support: Yup.string().required(),
    username: Yup.string().required("Please enter your password"),
    email: Yup.string()
        .matches(emailValidationRegex, "Invalid email address")
        .required("Please enter your email"),
    phoneNo: Yup.string()
        .matches(/^[0-9 ()-]+$/, "Phone number must only contain digits")
        .min(9, "Phone number is too short")
        .max(11, "Phone number is too long"),
    message: Yup.string().required("Please enter your message")
})

const SupportForm = () => {

    const initialValues = {
        support: '',
        username: '',
        email: '',
        phoneNo: '',
        message: '',
    }

    const supportOptions = [
        { value: "General Inqury", label: "General Inqury" },
        { value: "Technical Support", label: "Technical Support" },
        { value: "Post An Event", label: "Post An Event" },
        { value: "Others", label: "Others" },
    ]

    const onFormSubmit = (values, { setSubmitting, resetForm }) => {
        console.log('Submitted values:', values);
        setSubmitting(false);
        resetForm();
    };


    return (
        <div className='p-4 lg:p-8 space-y-6 rounded shadow-[0_0_15px_rgba(0,0,0,0.1)]'>
            <h2 className='text-xl font-medium'>Create a support</h2>

            <Formik
                initialValues={initialValues}
                onSubmit={onFormSubmit}
                validationSchema={supportValidationSchema}
            >
                {() => (
                    <Form className='space-y-4'>

                        <div className='font-medium'>Support Type *</div>

                        <FormikDropdown
                            name="support"
                            options={supportOptions}
                        />

                        <TextField
                            label="Full Name *"
                            name="username"
                            type="username"
                            autoComplete={'username'}
                        />

                        <TextField
                            label="Email *"
                            name="email"
                            type="email"
                            autoComplete={'email'}
                        />

                        <TextField
                            label="Phone"
                            name="phoneNo"
                            type="number"
                            autoComplete={'tel'}
                        />

                        <FormikTextArea
                            id={"message"}
                            name={"message"}
                            placeholder={"Your message"}
                            rows={5}
                        />

                        <div className='w-full flex justify-center items-center'>
                            <Button
                                className='text-sm font-medium rounded-md text-white px-6 py-3 bg-primary'
                                type='submit'
                                label={'Submit'}
                            />
                        </div>
                    </Form>
                )}
            </Formik >

        </div>

    )
}

export default SupportForm