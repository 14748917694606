import React, { useState } from "react";
import { ErrorMessage, Field } from "formik";
import { TextError } from "../../index";

const InputField = ({ label, placeholder, optional, name, flag = false, focus = false, ...rest }) => {

    const [isFieldFoucsed, setIsFieldFoucsed] = useState(focus)

    const handleFocusChange = (event) => {
        if (event.type === "focus") {
            setIsFieldFoucsed(true);
        } else if (event.target.value !== "") {
            setIsFieldFoucsed(true);
        } else {
            setIsFieldFoucsed(false);
        }
    };

    return (
        <div className="w-full relative">
            <label
                htmlFor={name}
                className={`${isFieldFoucsed ? "-translate-y-1.5" : "translate-y-2"} text-customGray duration-100 ease-in-out pl-3 pt-3 absolute top-0 left-0 flex text-sm items-center justify-between`}
            >
                <div className="flex whitespace-nowrap items-center justify-between gap-x-3 w-full">

                    <div>{label}</div>

                    <ErrorMessage name={name} component={TextError} />
                    {optional && (
                        <div className="text-[#5D6B82] text-sm">{optional}</div>
                    )}

                </div>

            </label>

            {flag ? (
                <div className="w-full flex border border-gray-300 items-center rounded-lg">
                    <Field
                        as="select"
                        name="callingCode"
                        className="rounded-l-lg rounded-r-none border-r text-sm bg-white border-gray-300 w-fit h-full px-3 pb-3 pt-6 outline-none"
                    >
                        <option value="977"> +977</option>
                        <option value="61"> +61</option>
                    </Field>

                    <Field
                        id={name}
                        name={name}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        {...rest}
                        className="rounded-r-lg rounded-l-none w-full h-full px-3 pb-3 pt-6  text-sm outline-none"
                        placeholder={placeholder}
                    />
                </div>
            ) : (
                <div>
                    <Field
                        id={name}
                        name={name}
                        {...rest}
                        className="border border-gray-300 w-full px-3 pb-3 pt-6 text-sm rounded-lg outline-gray-300"
                        onFocus={handleFocusChange}
                        onBlur={handleFocusChange}
                        onInput={handleFocusChange}
                    />
                </div>
            )}
        </div>
    );
};

export default InputField;
