import React, { useState } from 'react'
import { NavLink, useLocation, Outlet } from 'react-router-dom'
import { PiHouseSimpleThin } from "react-icons/pi";
import { FaChevronRight } from "react-icons/fa6";
import { upperFirst } from '../../utils/helpers';


const BreadCrumbs = () => {


    const [customPath, setCustomPath] = useState('')

    const location = useLocation()

    let currentLink = ''

    const crumbs = location.pathname
        .split("/")
        .filter(crumb => crumb !== "")
        .map(crumb => {
            currentLink += `/${crumb}`

            return (
                <div key={crumb} className=''>
                    <NavLink
                        to={currentLink} end
                        className={({ isActive, isPending, isTransitioning }) =>
                            [
                                isPending ? "pending" : "",
                                isActive ? "font-medium text-black" : "font-[400] text-[#70747D]",
                                isTransitioning ? "transitioning" : "",
                                "flex items-center gap-x-3"
                            ].join(" ")
                        }
                    >
                        <FaChevronRight className='text-xs md:text-base text-[#70747D]' />
                        <div className='text-sm whitespace-nowrap hover:text-hover hover:underline'>{upperFirst(crumb)}</div>
                    </NavLink>
                </div>
            )
        })

    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"


    return (
        <>
            <div className={`${sectionPad} bg-[#F5F5F5] overflow-y-auto w-full py-5 flex gap-x-4 gap-y-2 items-center justify-start`}>
                <NavLink
                    to="/"
                    className={({ isActive, isPending, isTransitioning }) =>
                        [
                            isPending ? "pending" : "",
                            isActive ? "font-medium" : "font-[400] text-[#70747D]",
                            isTransitioning ? "transitioning" : "",
                            "flex items-center justify-start gap-x-3"
                        ].join(" ")
                    }
                >
                    <div className='flex items-center just gap-2'>
                        <PiHouseSimpleThin className='text-2xl' />
                        <div className='text-sm whitespace-nowrap hover:text-hover hover:underline'>Home</div>
                    </div>

                </NavLink>

                {customPath ? (

                    customPath.map((path, index) => (
                        <span key={index}>

                            <NavLink
                                to={path.url}
                                className={({ isActive, isPending, isTransitioning }) =>
                                    [
                                        isPending ? "pending" : "",
                                        isActive ? "font-medium text-black" : "font-[400] text-[#70747D]",
                                        isTransitioning ? "transitioning" : "",
                                        "flex items-center gap-x-3"
                                    ].join(" ")
                                }
                            >
                                <FaChevronRight className='text-xs md:text-base text-[#70747D]' />
                                <div className='text-sm whitespace-nowrap hover:text-hover hover:underline'>{path.label}</div>                            </NavLink>
                        </span>
                    )))
                    :
                    (
                        crumbs
                    )}

            </div>
            <Outlet context={[customPath, setCustomPath]} />
        </>
    )
}

export default BreadCrumbs