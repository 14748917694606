import React from 'react'
import { ErrorMessage, Field } from 'formik'
import { TextError } from '../../index'

const FormikTextArea = ({ label, id, name, rows = 5, placeholder }) => {
    return (
        <div className='relative'>
            <label
                htmlFor={name}
                className="absolute top-0 right-0 translate-y-2 -translate-x-2 text-black flex text-sm items-center justify-between"
            >
                <div className="whitespace-nowrap">
                    {label}
                </div>

                <ErrorMessage name={name} component={TextError} />
            </label>
            <Field
                as='textarea'
                id={id}
                name={name}
                rows={rows}
                placeholder={placeholder}
                className='border border-gray-300 w-full px-3 pb-3 pt-6 text-sm rounded-lg outline-gray-300'
            />

        </div>
    )
}

export default FormikTextArea