import { useInView } from 'framer-motion';
import React, { useRef } from 'react'

const RevealOnScroll = ({ children, className }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <section ref={ref} className='overflow-hidden'>
            <span className={`relative transition-all duration-500 ${isInView ? 'top-0 opacity-100 text-black' : 'top-[100px] opacity-0 text-gray-500'} ${className}`} style={{ willChange: 'transform, opacity, color' }}>
                {/* <span className={`${isInView ? "opacity-100 top-0" : "opacity-0 top-[100px]"} relative transition-all duration-500`} style={{ willChange: 'transform' }}> */}
                {children}
            </span>
        </section>
    )
}

export default RevealOnScroll
