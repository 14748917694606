export const storeUserData = (data) => ({
    type: 'STORE_USER_INFO',
    payload: data
})

export const cleanUserData = () => ({
    type: 'CLEAN_USER_INFO',
})

export const eventBookData = (data) => ({
    type: 'STORE_EVENT_BOOK_INFO',
    payload: data
})

export const cleanEventBook = () => ({
    type: 'CLEAN_EVENT_BOOK_INFO',
})