import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { scrollToTop } from '../../utils/scrollToTop'


const FeaturedEventsCard = ({ image,
    label,
    id,
    location,
    className,

    handleMouseDown,
    handleMouseMove,
    setIsMouseDown,
    condition
}) => {

    const navigate = useNavigate()

    const handleMouseUp = () => {
        setIsMouseDown && setIsMouseDown(false);
        if (condition) { executeFunctions() }
    };

    const executeFunctions = () => {
        scrollToTop();

        const queryParams = new URLSearchParams();
        queryParams.set('id', id);
        queryParams.set('lbl', label);
        navigate(`/concert?${queryParams}`);
    };


    return (
        <div
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            className={`${className} overflow-hidden w-full group cursor-pointer flex flex-col justify-start items-start gap-3`}
        >

            <div className='w-full aspect-[9/11] rounded-md overflow-hidden'>
                <img src={image} className='w-full h-full group-hover:scale-110 duration-500 ease-in-out object-cover' alt="" />
            </div>

            <div className='space-y-1'>
                <Link to={`/concert/${label}`} className='pointer-events-none text-lg font-medium bg-gradient-to-r from-black to-black bg-[length:0%_2px] bg-left-bottom bg-no-repeat transition-all duration-500 group-hover:bg-[length:100%_2px]'>
                    {label.toUpperCase()}
                </Link>

                <div className='text-customGray text-sm'>{location}</div>
            </div>

        </div>
    )
}

export default FeaturedEventsCard