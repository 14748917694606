import React from 'react'
import { Button, OverlayLoading, TextField } from '../../components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useForgotPass } from '../../apis/AuthApi'


const ResetValidationSchema = Yup.object({
    email: Yup.string().email().required("Please enter your email"),
})


const ResetPassword = () => {

    const { mutate: forgotMutate, isPending } = useForgotPass();

    const initialValues = {
        email: ""
    }

    const onFormSubmit = (val, action) => {
        forgotMutate(val)
        action.resetForm()
    }

    return (
        <>
            {isPending &&
                <OverlayLoading />
            }

            <div className='bg-[#E5F0FD] py-12 edge-spacing'>

                <Formik
                    initialValues={initialValues}
                    onSubmit={onFormSubmit}
                    validationSchema={ResetValidationSchema}
                >
                    {() => (
                        <Form className='p-4 md:p-8 lg:p-12 space-y-4 bg-white md:w-[80%] lg:w-1/2 m-auto rounded-md shadow-[0_0_10px_rgba(0,0,0,0.1)]'>

                            <div className='font-medium text-xl'>Reset Password</div>


                            <div className='text-customGray'>Password reset link will be sent to your email</div>


                            <div>
                                <TextField
                                    label="Email"
                                    name="email"
                                    type="email"
                                    autoComplete={'email'}
                                />
                            </div>


                            <div className='flex justify-center items-center'>
                                <Button
                                    className='mt-8 text-sm font-medium rounded-md text-white px-6 py-3 bg-primary'
                                    type='submit'
                                    label={'GET OTP'}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>
        </>
    )
}

export default ResetPassword