import React, { useEffect, useMemo, useState } from 'react'
import { Button, RevealOnScroll, AllEventsCard, Cat2Loading } from '../../components/index'
import { useCategories } from '../../apis/EventApi'
import { eventView } from '../../shared/constants'
import { useSearchParams } from 'react-router-dom'
import { formatDate } from '../../utils/helpers'



const AllEvents = () => {


    const [searchParams] = useSearchParams()
    const view = searchParams.get('view')

    const allEventsOption = [eventView.pop, eventView.feat, "All"]
    const [activeEventPage, setActiveEventPage] = useState(allEventsOption[2])

    useEffect(() => {
        setActiveEventPage(view)
    }, [view])


    const { data: catList } = useCategories();
    const catData = catList?.categories;

    const featureCat = useMemo(() => catData?.filter(data => data.status === "active").find(val => val.title === "featured")?.event_info || [], [catData])
    const popularCat = useMemo(() => catData?.filter(data => data.status === "active").find(val => val.title === "popular")?.event_info || [], [catData])
    const uniqueCat = useMemo(() => [...new Map([...featureCat, ...popularCat].map(item => [item.id, item])).values()], [featureCat, popularCat])

    const allEvents = useMemo(() => {
        if (activeEventPage === eventView.pop) { return popularCat }
        else if (activeEventPage === eventView.feat) { return featureCat }
        else return uniqueCat
    }, [activeEventPage, popularCat, featureCat, uniqueCat])

    /**
     * Test data
     * 
    const allEvents = [
        { id: 1, image: homeBG_Img, label: "Captain Sparrow And the Pharroh", date: "9:11 am, 11 Sept, 2001" },
        { id: 2, image: homeBG_Img, label: "Lost Between Towers: A Pilot's Tale of Survival", date: "9:11 am, 11 Sept, 2001" },
        { id: 3, image: homeBG_Img, label: "Sabin Sparrow And the Pharroh", date: "9:11 am, 11 Sept, 2001" },
        { id: 4, image: homeBG_Img, label: "Captain Sparrow And the Sabin", date: "9:11 am, 11 Sept, 2001" },
    ]
    */


    return (
        <div className='edge-spacing py-12 divide-y'>

            <div className='space-y-8 pb-10'>

                <RevealOnScroll>
                    <div className='space-y-8 py-4'>
                        <h1 className='font-semibold text-3xl text-center w-full'>All Active Events</h1>

                        <div className='w-full overflow-y-auto mx-0 px-0'>
                            <div className='flex justify-center items-center gap-3'>
                                {allEventsOption?.map((label, index) => (
                                    <Button
                                        key={index}
                                        onClick={() => setActiveEventPage(label)}
                                        className={`${activeEventPage === label ? "bg-secondary text-white" : "bg-[#F5F5F5] text-secondary"} 
                                        whitespace-nowrap rounded py-3 px-4 text-sm font-medium`}
                                        label={label}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </RevealOnScroll>


                {!catList ?
                    <Cat2Loading /> :
                    <RevealOnScroll>
                        <div className='grid gap-3 gap-y-10 md:gap-x-6 grid-cols-[repeat(auto-fill,minmax(170px,1fr))] lg:grid-cols-[repeat(auto-fill,minmax(230px,1fr))]'>
                            {allEvents?.map((val) => (
                                <AllEventsCard
                                    key={val.id}
                                    id={val.id}
                                    image={val.image_url}
                                    label={val.title}
                                    subLabel={formatDate(val.start)}
                                />
                            ))}
                        </div>
                    </RevealOnScroll>
                }

            </div>

            {/* <div className='py-8 flex justify-between items-center gap-3'>
                <Button label={"Previous"} />

                <div>
                    <Button
                        className={"bg-[#051036] rounded-full w-10 aspect-square text-white"}
                        label={"1"}
                    />
                </div>
                <Button label={"Next page"} />
            </div> */}

        </div>
    )
}

export default AllEvents