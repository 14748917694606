import { useRef, useEffect } from 'react';

export const useHoverMenu = (setToggle) => {
    const menuRef = useRef(null);
    const disableMenuRef = useRef(true);

    useEffect(() => {
        setTimeout(() => {
            disableMenuRef.current = false;
        }, 500);
    }, []);


    useEffect(() => {
        const handleMouseLeave = () => {
            if (!disableMenuRef.current) {
                setToggle(false);
            }
        };

        const currentMenuRef = menuRef.current;

        if (currentMenuRef) {
            currentMenuRef.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (currentMenuRef) {
                currentMenuRef.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, [setToggle]);



    return menuRef;
};

/**
 * For mouse-hover menu
 * 
 * Usage:
 *   1. const menuRef = useHoverMenu(yourSetterFunction);
 *   2. Attach the returned menuRef to the parent div of the menu container:
 *        <div ref={menuRef}... >
 *   3. In the button that triggers the menu:
 *        <div ref={menuRef}>
 *          <button onMouseEnter={openMenuSetter} />
 *        </div>
 * 
 */