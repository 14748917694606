import React from 'react'
import SecondFooter from '../components/Footer/SecondFooter'
import { Outlet, useLocation } from 'react-router-dom'
import { HomeFeedback } from '../components'

const SecondFooterLayout = () => {

    const location = useLocation()
    const isHome = (location.pathname === "/") ? true : false

    return (
        <>
            <Outlet />
            <SecondFooter />

            {isHome &&
                <HomeFeedback />
            }
        </>
    )
}

export default SecondFooterLayout