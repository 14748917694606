import React from 'react'
import { logoImg } from '../../assets/index'

const AboutUs = () => {
    return (
        <div className='edge-spacing py-12 text-secondary'>

            <div className='w-full flex flex-col md:flex-row justify-center items-center gap-8'>
                <div className='p-2 md:p-8 w-full md:md:w-1/2 flex flex-col justify-center items-start gap-8'>
                    <h1 className='font-semibold text-3xl'>About Ola Ticket</h1>

                    <p className='text-justify md:w-[90%]'>Ola ticket is an online ticketing system. Ola ticket helps you to book your favorite concerts or movie tickets anytime anywhere.</p>
                </div>

                <div className='p-2 md:p-8 w-full md:md:w-1/2'>

                    <div className='w-full'>
                        <img src={logoImg} className='w-full h-full object-contain' alt="" />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default AboutUs