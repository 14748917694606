import React from 'react'
import { EventBody } from '../../components'
import { useCategories } from '../../apis/EventApi'

const Marketing = () => {

    const { data: catList } = useCategories();
    const catData = catList?.categories;

    const marketCat = catData?.filter(data => data.status === "active").find(val => val.title === "Marketing")?.event_info;


    return (
        <>
            <EventBody catArr={marketCat} label={"Marketing"} />
        </>
    )
}

export default Marketing