
const initialState = {
    height: null,
}

const navReducer = (state = initialState, action) => {
    switch (action.type) {

        case "NAVBAR_HEIGHT":
            return {
                ...state,
                height: action.payload
            }

        default:
            return state
    }
}

export default navReducer