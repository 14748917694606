import React from 'react'

const HisLoadSkeli = () => {
    return (
        <div className="edge-spacing bg-[#E0ECF9] py-12">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-3">
                <div className="w-full bg-white shadow-md rounded p-4 animate-pulse">
                    <div className="flex items-center">
                        <div className="w-16 h-16 rounded-full bg-gray-200 mr-4"></div>
                        <div>
                            <div className="h-4 bg-gray-200 rounded w-24 mb-2"></div>
                            <div className="h-4 bg-gray-200 rounded w-36"></div>
                        </div>
                    </div>
                    <div className="mt-4 space-y-2">
                        <div className="flex justify-center items-start">
                            <strong className="w-1/2 lg:w-1/3 h-4 bg-gray-200 rounded"></strong>
                            <span className="w-1/2 lg:w-2/3 h-4 bg-gray-200 rounded"></span>
                        </div>
                        <div className="flex justify-center items-start">
                            <strong className="w-1/2 lg:w-1/3 h-4 bg-gray-200 rounded"></strong>
                            <span className="w-1/2 lg:w-2/3 h-4 bg-gray-200 rounded"></span>
                        </div>
                    </div>
                </div>

                <div className="w-full bg-white shadow-md rounded p-4 animate-pulse">
                    <div className="flex items-start w-full h-full justify-center flex-wrap lg:flex-nowrap gap-3">
                        <div className="w-full md:max-h-40 lg:w-44 md:aspect-square rounded overflow-hidden bg-gray-200"></div>
                        <div className="w-full">
                            <div className="h-4 bg-gray-200 rounded w-36 mb-2"></div>
                            <div className="flex justify-center items-start">
                                <strong className="w-1/2 lg:w-1/3 h-4 bg-gray-200 rounded"></strong>
                                <span className="w-1/2 lg:w-2/3 h-4 bg-gray-200 rounded"></span>
                            </div>
                            <div className="flex justify-center items-start">
                                <strong className="w-1/2 lg:w-1/3 h-4 bg-gray-200 rounded"></strong>
                                <span className="w-1/2 lg:w-2/3 h-4 bg-gray-200 rounded"></span>
                            </div>
                            <div className="flex justify-center items-start">
                                <strong className="w-1/2 lg:w-1/3 h-4 bg-gray-200 rounded"></strong>
                                <span className="w-1/2 lg:w-2/3 h-4 bg-gray-200 rounded"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 gap-3">
                <div className="bg-white shadow-md rounded p-4 w-full animate-pulse">
                    <div className="h-4 bg-gray-200 rounded w-36 mb-2"></div>
                    <div className="flex justify-center items-start">
                        <strong className="w-1/2 md:w-1/3 h-4 bg-gray-200 rounded"></strong>
                        <span className="w-1/2 md:w-2/3 h-4 bg-gray-200 rounded"></span>
                    </div>
                    <div className="flex justify-center items-start">
                        <strong className="w-1/2 md:w-1/3 h-4 bg-gray-200 rounded"></strong>
                        <span className="w-1/2 md:w-2/3 h-4 bg-gray-200 rounded"></span>
                    </div>
                    <div className="flex justify-center items-start">
                        <strong className="w-1/2 md:w-1/3 h-4 bg-gray-200 rounded"></strong>
                        <span className="w-1/2 md:w-2/3 h-4 bg-gray-200 rounded"></span>
                    </div>
                    <div className="flex justify-center items-start">
                        <strong className="w-1/2 md:w-1/3 h-4 bg-gray-200 rounded"></strong>
                        <span className="w-1/2 md:w-2/3 h-4 bg-gray-200 rounded"></span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default HisLoadSkeli