
const initialState = {
    isProfileModalOpen: false,
}

const profileModalReducer = (state = initialState, action) => {
    switch (action.type) {

        case "IS_PROFILE_OPEN":
            return {
                ...state,
                isProfileModalOpen: true
            }

        case "IS_PROFILE_CLOSE":
            return {
                ...state,
                isProfileModalOpen: false
            }

        default:
            return state
    }
}

export default profileModalReducer