import React from 'react'
import { useNavigate } from 'react-router-dom'
import { scrollToTop } from '../../utils/scrollToTop'

const AllEventsCard = ({ id, image, label, subLabel }) => {

    const navigate = useNavigate()

    const executeFunctions = () => {
        scrollToTop();

        const queryParams = new URLSearchParams();
        queryParams.set('id', id);
        queryParams.set('lbl', label);
        navigate(`/concert?${queryParams}`);
    };

    return (
        <div
            onClick={executeFunctions}
            className='overflow-hidden w-full group cursor-pointer flex flex-col justify-start items-start gap-3'
        >

            <div className='w-full aspect-[9/11] rounded-md overflow-hidden'>
                <img src={image} className='w-full h-full group-hover:scale-105 duration-200 ease-in-out object-cover' alt="" />
            </div>

            <div className='space-y-1'>
                <div className='text-lg font-medium'>{label.toUpperCase()}</div>
                <div className='text-customGray text-sm'>{subLabel}</div>
            </div>

        </div>
    )
}

export default AllEventsCard