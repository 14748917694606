import { toast } from 'react-toastify';
import dayjs from "dayjs";

/**
 * @param {string} str 
 * @return {string} 
 */
export const upperFirst = (str) => {
    return str
        .split('-')
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        .join(' ')
}

/**
 * @param {'success' | 'error'} type
 * @param {string} message
 */
export const showToast = (type, message) => {
    if (type === 'success') {
        toast.success(message);
    } else if (type === 'error') {
        toast.error(message);
    }
};

export const handleError = (error) => {
    let message = error.response?.data?.message || 'An error occurred. Please try again.';
    if (error.response?.data?.message === "Invalid access token") {
        message = "Your session has expired. Please log in again to continue."
    }
    showToast('error', message);
};


/**
 * @param {string} date
 * @return {string}
 */
export const formatDate = (date) => {
    const formattedTime = dayjs(date).format('hh:mm A, DD MMM, YYYY');
    return formattedTime;
}

export const displayDate = (date) => {
    const formattedTime = dayjs(date).format('DD-MM-YYYY');
    return formattedTime;
}

/**
 * 
 * @param {number} usd 
 * @returns {number}
 */
export const convertAudToCents = (aud) => {
    if (isNaN(aud)) {
        throw new Error('Input must be a number');
    }
    // Ensure the input is a valid floating-point number
    const validAud = parseFloat(aud);
    // Multiply by 100 and round to nearest integer to avoid floating-point precision issues
    return Math.round(validAud * 100);
};


/**
 * 
 * @param {number} cents 
 * @returns {number}
 */
export const convertCentsToAud = (cents) => {
    if (isNaN(cents)) {
        throw new Error('Input must be a number');
    }
    // Ensure the input is a valid integer
    const validCents = parseInt(cents, 10);
    // Divide by 100 to convert to AUD and return as a number
    return validCents / 100;
};