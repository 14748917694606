import React from 'react'
import { FaUser } from "react-icons/fa";
import { useGetProfile } from '../../apis/AuthApi';
import { upperFirst } from '../../utils/helpers';

const ProfileModalContent = () => {

    const { data: catList } = useGetProfile();
    const info = catList?.user_detail;

    const userData = {
        image: info?.user_info?.image_url,
        Role: info?.role ? upperFirst(info?.role) : "",
        Name: info?.name,
        Email: info?.email,
        Contact: info?.user_info?.phone,
    }

    return (
        <div className='w-[80vw] py-4 flex flex-col justify-center items-center gap-3'>

            {userData.image ?
                <div className='rounded-full aspect-square w-20 bg-gray-300 overflow-hidden'>
                    <img src={userData.image} className='w-full h-full object-cover' alt="" />
                </div>
                :
                <FaUser className='w-20' />
            }

            <div className='flex flex-col gap-3 justify-center items-center'>
                <div className='whitespace-nowrap'>Role: <span className='font-semibold'>{userData.Role}</span></div>
                <div className='whitespace-nowrap'>Name: <span className='font-semibold'>{userData.Name}</span></div>
                <div className='whitespace-nowrap'>Email: <span className='font-semibold'>{userData.Email}</span></div>
                <div className='whitespace-nowrap'>Contact: <span className='font-semibold'>{userData.Contact}</span></div>
            </div>
        </div>
    )
}

export default ProfileModalContent