import React from 'react'
import Button from '../common/Button/Button'
import { useNavigate } from 'react-router-dom'
import { scrollToTop } from '../../utils/scrollToTop'

const PopularEventsCard = (props) => {
    const {
        image,
        label,
        id,
        date,
        className,

        handleMouseDown,
        handleMouseMove,
        setIsMouseDown,
        condition
    } = props


    const navigate = useNavigate()

    const executeFunctions = () => {
        scrollToTop();

        const queryParams = new URLSearchParams();
        queryParams.set('id', id);
        queryParams.set('lbl', label);
        navigate(`/concert?${queryParams}`);
    };


    const handleMouseUp = () => {
        setIsMouseDown && setIsMouseDown(false);
        if (condition) { executeFunctions() }
    };


    return (
        <div
            style={{
                backgroundImage: `url("${image}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            className={`${className} relative overflow-hidden w-full aspect-[9/11] group rounded-md cursor-pointer`}
        >
            {/* label */}
            <div className='absolute text-white w-[95%] z-[2] top-[10%] left-1/2 -translate-x-1/2 text-center opacity-0 select-none group-hover:opacity-100'>{date}</div>
            <div className='text-white w-full h-full group-hover:bg-[rgba(0,0,0,0.5)] duration-200'></div>

            <div className='absolute space-y-4 p-4 w-full group-hover:bottom-0 -bottom-[75px] duration-200'>
                <div className='px-4 py-2 font-bold text-white text-center bg-[#3E374E] w-full rounded'>{label?.toUpperCase()}</div>

                <Button
                    onClick={executeFunctions}
                    className={"w-full font-medium px-8 py-4 rounded bg-white text-secondary hover:bg-primary hover:text-white"}
                    label={"Detail"}
                />
            </div>

        </div>
    )
}

export default PopularEventsCard