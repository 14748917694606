import React, { useMemo } from 'react';
import { Button, OverlayLoading, Table } from '../../components';
import { useCatDetail, useEventBook } from '../../apis/EventApi';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import LocationDataTable from './LocationDataTable';




const TicketInfo = ({ id }) => {


    const { mutate: eventBookMutate, isPending: isBooking, status } = useEventBook();

    const { data: catDetail } = useCatDetail(id);
    const catData = catDetail?.event_detail;
    const locData = catData?.venue_info;
    const artistData = catData?.artist_info;
    const ticketData = catData?.ticket_info;


    const initialValues = useMemo(() => ({
        coupon_code: '',
        ticket_detail: ticketData?.map(val => ({
            "ticket_id": val.id,
            "total_ticket": 0
        })) || []
    }), [ticketData]);


    const onFormSubmit = (values, actions) => {

        if (values.ticket_detail.every(detail => detail.total_ticket === 0)) {
            toast.info("Please select at least one ticket before proceeding.");
            return;
        }

        const formattedData = {
            ...values,
            event_id: catData.id,
        };

        eventBookMutate(formattedData);

        if (status === "success") {
            actions.resetForm();
        }
    }


    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: onFormSubmit,
    });



    const handleQuantityBlur = (e, index, setFieldValue) => {
        const { value } = e.target;
        const newQuantity = parseInt(value, 10);
        if (!isNaN(newQuantity)) {
            const maxTicket = ticketData[index].remaining;
            const quantity = Math.min(Math.max(newQuantity, 0), maxTicket);
            setFieldValue(`ticket_detail.${index}.total_ticket`, quantity);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    // Table body
    const tickteTableBody = ticketData?.map((val, index) => [
        val?.kind_info?.title,
        `$${val?.price}`,
        val?.remaining === 0 ? <p className='text-red-500'>Sold Out</p> : `${val?.remaining} tickets`,

        val?.remaining === 0 ? <></> :
            <input
                key={val?.id}
                className="border py-1 px-3 rounded w-full outline-none border-gray-500"
                id={val?.id}
                type="number"
                name={`ticket_detail.${index}.total_ticket`}
                value={formik.values?.ticket_detail?.[index]?.total_ticket}
                onChange={formik.handleChange}
                onBlur={(e) => handleQuantityBlur(e, index, formik.setFieldValue)}
                onKeyDown={handleKeyDown}
            />
    ]);



    return (
        <>
            {isBooking &&
                <OverlayLoading />
            }

            <div className='space-y-4'>
                <form onSubmit={formik.handleSubmit} className='space-y-4'>

                    <div className='w-full rounded border p-4 divide-y'>
                        <div className='font-medium py-4'>Location Detail</div>

                        <div className='py-4 text-sm'>
                            <LocationDataTable locData={locData} />
                        </div>
                    </div>

                    <div className='w-full rounded border p-4 lg:py-6 divide-y'>
                        <div className='font-medium py-4'>Artists</div>
                        <div className='grid gap-3 grid-cols-[repeat(auto-fill,minmax(7rem,1fr))]'>
                            {artistData?.map(val => (
                                <div key={val.id} className='pt-4 lg:pt-6 text-sm space-y-3 flex flex-col justify-start items-center w-fit'>
                                    <div className='aspect-square w-20 rounded-full overflow-hidden border'>
                                        <img src={val?.image_url} className='w-full h-full object-cover' alt="artist" />
                                    </div>
                                    <div>{val?.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <section className='pt-12 space-y-4'>
                        <h2 className='text-xl font-medium'>Ticket Availability</h2>

                        <Table
                            headItems={["Ticket Kind", "Price (AUD)", "Remaining", "Booking"]}
                            bodyItems={tickteTableBody}
                            colWidth={["w-1/4", "w-1/4", "w-1/4", "w-1/4"]}
                        />

                        <h3 className='text-xl font-medium'>Coupon Code</h3>

                        <input
                            className="border p-2 rounded min-w-60 outline-none border-gray-500"
                            type="text"
                            name="coupon_code"
                            placeholder='Coupon code'
                            value={formik.values.coupon_code}
                            onChange={formik.handleChange}
                        />

                        <p className='py-2 text-sm'>* 0% admin fee will be charged while purchasing of ticket.</p>

                    </section>

                    <Button
                        type='submit'
                        className="bg-primary px-4 py-2 text-white rounded"
                        label="Next"
                    />
                </form>
            </div>
        </>
    );
};

export default TicketInfo;
