import React from 'react';
import { TextError } from "../../index";
import { Field, ErrorMessage } from 'formik';

const FormikDropdown = ({ name, options, label }) => {

    const defaultOption = { value: '', label: 'Please Support Type' };
    const dropdownOptions = [defaultOption, ...options];

    return (
        <div className='w-full'>
            <label
                htmlFor={name}
                className="pb-2 text-black flex text-sm items-center justify-between"
            >
                <div className="whitespace-nowrap">
                    {label}
                </div>

                <ErrorMessage name={name} component={TextError} />
            </label>
            <Field as="select" id={name} name={name} className={"w-full border border-gray-300 px-3 py-4 rounded-lg outline-gray-300"}>
                {dropdownOptions.map((option) => (
                    <option key={option.value} value={option.value} className="w-full border border-gray-300">
                        {option.label}
                    </option>
                ))}
            </Field>
        </div>
    );
};

export default FormikDropdown;
