import React from 'react'
import { Button, OverlayLoading, TextField } from '../../../components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useRegister } from '../../../apis/AuthApi'


const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const RegisterValidationSchema = Yup.object({
    email: Yup.string()
        .matches(emailValidationRegex, "Invalid email address")
        .required("Please enter your email"),
});


const Register = () => {

    const navigate = useNavigate()

    const initialValues = {
        email: ""
    }

    const { mutate: registerMutate, isPending: isRegistering } = useRegister();


    const onFormSubmit = (val, action) => {
        registerMutate({
            ...val,
            "role": "user"
        })

        action.resetForm()
    }

    return (
        <>
            {isRegistering &&
                <OverlayLoading />
            }

            <div className='bg-[#E5F0FD] py-12 edge-spacing'>

                <Formik
                    initialValues={initialValues}
                    onSubmit={onFormSubmit}
                    validationSchema={RegisterValidationSchema}
                >
                    {() => (
                        <Form className='p-4 md:p-8 lg:p-12 space-y-4 bg-white md:w-[80%] lg:w-1/2 m-auto rounded-md shadow-[0_0_10px_rgba(0,0,0,0.1)]'>

                            <div className='font-medium text-xl'>Create an account</div>

                            <div className='flex items-center justify-start gap-1'>

                                <div className='text-customGray'>Already have an account?</div>
                                <Button
                                    onClick={() => navigate("/user/login")}
                                    className={"text-primary"}
                                    label={"Log in"}
                                />

                            </div>

                            <div>
                                <TextField
                                    label="Email"
                                    name="email"
                                    type="email"
                                    autoComplete={'email'}
                                />
                            </div>


                            <Button
                                className='mt-8 text-sm font-medium rounded-md text-white px-6 py-3 bg-primary'
                                type='submit'
                                label={'GET OTP'}
                            />
                        </Form>
                    )}
                </Formik>

            </div>
        </>

    )
}

export default Register