import React, { useState } from "react";
import { ErrorMessage, Field } from "formik";
import { TextError } from "../../index";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const PasswordField = ({
    label,
    placeholder,
    name,
    setShowPassword,
    showPassword,
    ...rest
}) => {

    const [isFieldFoucsed, setIsFieldFoucsed] = useState(false)

    const handleFocusChange = (event) => {
        if (event.type === "focus") {
            setIsFieldFoucsed(true);
        } else if (event.target.value !== "") {
            setIsFieldFoucsed(true);
        } else {
            setIsFieldFoucsed(false);
        }
    };

    return (
        <div className="relative text-sm">
            <label
                htmlFor={name}
                className={`${isFieldFoucsed ? "-translate-y-1.5" : "translate-y-2"} z-[1] text-customGray duration-100 ease-in-out absolute top-0 left-0 pl-3 pt-3 flex text-sm items-center justify-between`}
            >
                <div className="flex whitespace-nowrap items-center justify-between gap-x-3 w-full">

                    <div>{label}</div>

                    <ErrorMessage name={name} component={TextError} />

                </div>
            </label>

            <div className="flex justify-between items-center relative">
                <Field
                    id={name}
                    name={name}
                    autoComplete={'current-password'}
                    {...rest}
                    className="border border-gray-300 w-full px-3 pb-3 pt-6 text-sm rounded-lg outline-gray-300"
                    // placeholder={placeholder}
                    onFocus={handleFocusChange}
                    onBlur={handleFocusChange}
                    onInput={handleFocusChange}
                />
                <div
                    className="absolute right-4 opacity-40"
                    onClick={() => setShowPassword(!showPassword)}
                >
                    {showPassword ? (
                        <AiFillEyeInvisible className="cursor-pointer" size={20} />
                    ) : (
                        <AiFillEye className="cursor-pointer" size={20} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PasswordField;
