import React from 'react';

const LocationDataTable = ({ locData }) => {
    return (
        <table className="table-auto w-full">
            <tbody className='space-y-2'>
                {locData?.venue && (
                    <tr className='flex justify-start items-start gap-1'>
                        <td className='w-[10%]'>Venue: </td>
                        <td className='w-[90%] text-customGray'>{locData?.venue}</td>
                    </tr>
                )}
                {locData?.address && (
                    <tr className="flex justify-start items-start gap-2">
                        <td className='w-[10%]'>Address:</td>
                        <td className="w-[90%] text-customGray">{locData.address}</td>
                    </tr>
                )}
                {locData?.contact_phone && (
                    <tr className="flex justify-start items-start gap-2">
                        <td className='w-[10%]'>Contact:</td>
                        <td className="w-[90%] text-customGray">{locData.contact_phone}</td>
                    </tr>
                )}
                {locData?.contact_email && (
                    <tr className="flex justify-start items-start gap-2">
                        <td className='w-[10%]'>Email:</td>
                        <td className="w-[90%] text-customGray">
                            <a href={`mailto:${locData?.contact_email}`} className="text-primary">
                                {locData?.contact_email}
                            </a>
                        </td>
                    </tr>
                )}
                {locData?.contact_website && (
                    <tr className="flex justify-start items-start gap-2">
                        <td className='w-[10%]'>Website:</td>
                        <td className='w-[90%]'>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={locData.contact_website}
                                className="text-primary"
                            >
                                {locData.contact_website}
                            </a>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default LocationDataTable;
