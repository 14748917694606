import React from 'react'
import Spinner from '../common/Spinner/Spinner'

const OverlayLoading = () => {
    return (
        <>
            <div className={`flex justify-center items-center z-50 bg-black opacity-25 top-0 left-0 w-screen fixed h-screen overflow-hidden`} >
                <Spinner className={"w-20 aspect-square"} />
            </div>
        </>
    )
}

export default OverlayLoading