import React from 'react'

const TermsAndConditions = () => {


    const termsAndConditions = [
        {
            section: "AMENDMENTS TO TERMS AND CONDITIONS",
            content: [
                "We reserve the right to amend these terms and conditions from time to time by either posting the changes on the EventManager Website or by giving you notice. It is your responsibility to check these websites for changes before creating each Event Listing.  The amendments will not apply to an event if the Event Listing was created before notice of the amendment is given."
            ]
        },
        {
            section: "PROVISION OF SERVICES",
            content: [
                "EventManager supplies the Platform for use by Event Organizers under a Software as a Service agreement. We do not represent you, the Organizer, nor are we an agent for you. The EventManager Platform allows Event Organizers to create, manage, accept payments and make refunds for tickets purchased via the platform. As an Organizer, you are responsible for all aspects of your event, including compliance with any existing legislation and taxation requirements.",
                "As an attendee, your use of the Platform to buy tickets or register for an event the following notice will indicate that you agree to be bound by the terms and conditions as amended. It is your responsibility to check the EventManager Website for changes before buying tickets or registering for an event. "
            ]
        },
        {
            section: "REGISTRATION",
            content: [
                "To be an Event Organizer or an attendee, you must register.  You must provide accurate complete and current information as required by the EventManager registration form. If you are an individual, you must be at least eighteen years old to register as an Event Organizer."
            ]
        },
        {
            section: "ACCOUNT PASSWORD AND SECURITY",
            content: [
                "As part of the registration process, you will create a password and account. You must maintain the confidentiality of the password and security of the account and are fully responsible for all use made of your account unless you can establish to our reasonable satisfaction that the use was unauthorized and did not result from a breach of these Terms."
            ]
        },
        {
            section: "USE OF THE EVENTMANAGER PLATFORM",
            content: [
                "EventManager grants you a non-exclusive, non-transferable right (without the right to sub-license) to access and use the EventManager platform solely for the purposes of creating an Event Listing to be used only for selling and distributing tickets to the event, collecting registrations for visitors to the event, and collecting, storing and accessing data concerning visitors and potential visitors to events.",
                "You must not remove, alter or hide any of the content on the EventManager Website or any Listing Content, other than your Listing Content."
            ]
        },
        {
            section: "YOUR DATA",
            content: [
                "You consent to EventManager retaining and using any data that you provide to EventManager, or which is obtained by EventManager in connection with your Events from third parties, in accordance with EventManager’s Privacy Policy. If there is no charge for ticketing at your Event, the purposes for which that data may be retained and used by EventManager or its related entities include marketing in accordance with EventManager’ Privacy Policy, unless this is prohibited by applicable legislation."
            ]
        },
        {
            section: "CODE OF CONDUCT",
            content: [
                "You agree to adhere to the following code of conduct, in the creation and management of your Event Listing, ticketing, and registration. Your Listing Content must include a statement of the terms on which tickets are sold to buyers and your Privacy Policy.  Your Privacy Policy must not be inconsistent with EventManager’s’ Privacy, and you must comply with both Privacy Policies in the collection, storage, use, and disclosure of User data."
            ]
        },
        {
            section: "PAYMENTS",
            content: [
                "EventManager supplies a platform under a Software as a Service agreement.  The platform offers a suite of functionality including the ability to accept payments for ticket purchases, donations, or other purchases for your event. In providing the ability to accept payments via EventManager’s payment gateway, we are required to comply with the relevant taxation obligations as prescribed by all relevant taxing agencies."
            ]
        },
        {
            section: "PROVISION OF TICKETS",
            content: [
                "Attendees may pay for tickets with a Visa, Mastercard, or where available, an American Express Credit Card.",
                "Tickets are provided electronically by email or SMS along with QR. All tickets issued by the EventManager Platform are secured with an encrypted key, contain a visible QR code and Ticket ID, and can only be validated if they can be scanned correctly at the event venue."
            ]
        },
        {
            section: "CHANGES TO AN EVENT OR TICKET",
            content: [
                "It is the responsibility of the Event Organizer to notify all Buyers of any material changes to an event or the ticket which they have purchased through the EventsPass Platform, including changes to the venue, program, performers, speakers, presenters, facilities, the quantity of the remaining tickets available or total tickets on offer."
            ]
        },
        {
            section: "CANCELED EVENTS",
            content: [
                "If you cancel an event for which you have created an Event Listing, you must notify EventManager immediately. You must refund in full any payments made by users in relation to that event (including any booking fee) or provide each user with an alternative remedy acceptable to that user (such as tickets to a rescheduled or another event)."
            ]
        },
        {
            section: "REFUNDS",
            content: [
                "EventManager does not give refunds or exchange tickets. If attendee print their ticket, and it is lost or stolen, it may be used by a third party to gain access to the event. If this occurs, attendees will not be able to obtain access to the event. The Event Organizer may be willing to cancel the ticket and issue a new one, but EventManager is not able to do so."
            ]
        },
        {
            section: "CANCELED EVENTS",
            content: [
                "It is the responsibility of the Event Organizer, not the EventManager, to advise you if your event is canceled.  You should immediately contact the Event Organizer to request a refund.  If the Event Organizer has not either refund your payment in full (including any booking fee) or provided an alternative remedy acceptable to you (such as tickets to a rescheduled or another event)."
            ]
        },
        {
            section: "COPYRIGHT",
            content: [
                "Copyright in the EventManager Platform (including text, graphics, logos, icons, sound recordings, and software) is owned or licensed by EventManager."
            ]
        },
        {
            section: "TERMINATION",
            content: [
                "EventManager, in its sole discretion, may terminate its agreement with you and your right to use the EventManager Platform and the Services at any time by giving you at least two weeks’ notice.  On termination or expiration of Event Managers agreement with you, it may deactivate, delete or bar your access to your account. EventManager may also delete all Event Content and files related to your account unless you have an Event Listing for an event that has not yet taken place (Future Event Listing)."
            ]
        }
    ];


    return (
        <>

            <div className='edge-spacing roboto bg-[#F7F7F7] py-12 pb-0'>

                <div className='edge-spacing'>
                    <h1 className='w-full text-center text-3xl text-secondary py-6'>TERMS AND CONDITION</h1>
                </div>


                <div className='border bg-white text-customGray pb-6'>

                    <h2 className='py-4 px-6 text-justify md:text-left '>Terms and Condition of OLA Tickets</h2>


                    <ol className='list-decimal'>
                        {termsAndConditions.map(val => (
                            <div key={val.title} className='py-4 pl-10 pr-6 md:px-12 text-justify md:text-left space-y-2'>
                                <li className='font-semibold text-left'>{val.section.toUpperCase()}</li>

                                <div className='space-y-6'>
                                    {val.content.map((item, index) => (
                                        <p key={index}>{item}</p>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </ol>
                </div>

                <div className='text-customGray text-sm pt-12 pb-6'>Ola Ticket 2024 © AusnepIT Solutions.</div>
            </div>
        </>)
}

export default TermsAndConditions