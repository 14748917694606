import React from 'react'
import RevealOnScroll from '../common/RevealOnScroll/RevealOnScroll'
import Cat2Loading from '../Loading/Cat2Loading'
import { useCategories } from '../../apis/EventApi';
import { noData } from '../../shared/constants';
import { AllEventsCard } from '..';

const EventBody = ({ catArr, label }) => {

    const { data: catList } = useCategories();

    return (
        <RevealOnScroll>
            <section className='edge-spacing py-12 space-y-8'>

                <h2 className='title'>{label}</h2>

                {!catList ?
                    <Cat2Loading /> : (
                        !catArr.length ?
                            noData :
                            <RevealOnScroll>
                                <div className='grid gap-3 gap-y-10 md:gap-x-6 grid-cols-[repeat(auto-fill,minmax(170px,1fr))] lg:grid-cols-[repeat(auto-fill,minmax(230px,1fr))]'>
                                    {catArr?.map((val) => (
                                        <AllEventsCard
                                            key={val.id}
                                            id={val.id}
                                            image={val.image_url}
                                            label={val.title}
                                            subLabel={val.venue}
                                        />
                                    ))}
                                </div>
                            </RevealOnScroll>
                    )}
            </section >
        </RevealOnScroll >
    )
}
export default EventBody