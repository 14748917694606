
const initialState = {
    userData: null,
    eventBookData: null
}

const storageReducer = (state = initialState, action) => {
    switch (action.type) {

        case "STORE_USER_INFO":
            return {
                ...state,
                userData: action.payload
            }

        case "CLEAN_USER_INFO":
            return {
                ...state,
                userData: null
            }

        case "STORE_EVENT_BOOK_INFO":
            return {
                ...state,
                eventBookData: action.payload
            }

        case "CLEAN_EVENT_BOOK_INFO":
            return {
                ...state,
                eventBookData: null
            }

        default:
            return state
    }
}

export default storageReducer