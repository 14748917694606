import React from 'react';

const PrivacyPolicies = () => {


    const headData = [
        {
            section: "Privacy Policy of Event Manager",
            content: [
                'Ola Ticket is committed to protecting your privacy. This Privacy Policy sets forth our policy for information that can be associated with or which relates to a person and/or could be used to identify a person ("Personal Data") collected from Users on or through the use of the Services. We take the privacy of your Personal Data seriously. Because of that, we have created this Privacy Policy.'
            ]
        }
    ]

    const privacyPolicy = [
        {
            section: "Personal Data",
            content: [
                "When you use EventManager to register for an event, become a member of the Organization, or use our software for Event management, we may collect your name, e-mail address, and other personally identifiable data. When you use EventManager to pay for an event or membership, we collect credit card information."
            ]
        },
        {
            section: "Information you Provide to Us",
            content: [
                "For all Users, we collect Personal Data when you voluntarily provide such information to the Services, such as when you register for access to the services, contact us with inquiries, respond to one of our surveys or browse or use certain parts of the Services. The Personal Data we may collect includes without limitation your name, address, email address, and any other information you choose to provide and/or enables Users to be personally identified."
            ]
        },
        {
            section: "Information We Automatically Collect",
            content: [
                "To enable certain functions and to improve your experience, we automatically collect information from Consumers when you are logged in to the Platform. This includes personal information and information about the services you use and how you use them."
            ]
        },
        {
            section: "Usage Information",
            content: [
                "When you are logged in, we collect information about your interactions with the Platform, such as the features you use, search terms you enter, pages you visit, etc."
            ]
        },
        {
            section: "In-App Content",
            content: [
                "To enable sync across devices, your in-app content, such as schedules, bookmarks, notes, etc., are collected."
            ]
        },
        {
            section: "Location Information",
            content: [
                "To personalize your Platform experience, we provide location services such as location-based notifications. We may collect information about your location based on your mobile device’s GPS when the app runs in the foreground or background. You can control or disable the use of location services in the device’s settings menu."
            ]
        },
        {
            section: "Information Collected from Consumers",
            content: [
                "If you are a Consumer, we may collect additional Personal Data from you, sometimes for our purposes and other times on behalf of an Organizer.",
                "We may also collect or receive Personal Data from third-party sources, such as Organizers, other Consumers, social media, or other third-party integrations."
            ]
        },
        {
            section: "Information Received from Organizers and Third-Parties",
            content: [
                "Organizers or Third-Party Services that use our Platform may upload information that could contain personal information about you. EventManager does not control, supervise or respond to how Organizers and Third-Parties providing your information process your Personal Data. Any information request regarding disclosing your personal information to EventManager should be directed to such third parties."
            ]
        },
        {
            section: "Information You Provide",
            content: [
                "Depending on the Organizer setup, you may be able to use the Platform without an account. Creating an account gives you access to additional features such as sync across devices. When you sign up for an account, we require basic information such as your first name, last name, email address, and password. You may choose to provide additional information as part of your profile."
            ]
        },
        {
            section: "Access and Use",
            content: [
                "If you provide Personal Data to obtain access to or use of the Services or any functionality thereof. In that case, we will use your Personal Data to provide you with access to or utilize the Services or functionality and analyze your use of such Services or functionality. For instance, if you are logged in to the Platform, we store and process information about you to provide Platform functionality."
            ]
        },
        {
            section: "Links to other websites",
            content: [
                "Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question."
            ]
        },
        {
            section: "Controlling your personal information",
            content: [
                "You may choose to restrict the collection or use of your personal information; if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us. We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so."
            ]
        },
        {
            section: "Security",
            content: [
                "We maintain security measures to protect your personal information from unauthorized access, misuse, or disclosure. You should remember that when you use our Site to post comments and share additional information, any information that you provide is not secure and can be collected and used by others. As a result, you should exercise caution before you make such disclosures."
            ]
        }
    ];



    return (
        <>

            <div className='edge-spacing roboto bg-[#F7F7F7] py-12 pb-0'>

                <h1 className='w-full text-center text-3xl text-secondary py-6'>PRIVACY POLICIES</h1>


                <div className='border bg-white text-customGray pb-6'>
                    {headData.map(val => (
                        <div key={val.title} className='py-4 px-6 text-justify md:text-left space-y-2'>
                            <p className='text-left'>{val.section}</p>

                            {val.content.map((item, index) => (
                                <p key={index}>{item}</p>
                            ))}
                        </div>
                    ))}


                    <ol className='list-decimal'>
                        {privacyPolicy.map(val => (
                            <div key={val.title} className='py-4 pl-10 pr-6 md:px-12 text-justify md:text-left space-y-2'>
                                <li>{val.section}</li>

                                {val.content.map((item, index) => (
                                    <p key={index}>{item}</p>
                                ))}
                            </div>
                        ))}
                    </ol>
                </div>

                <div className='text-customGray text-sm pt-12 pb-6'>Ola Ticket 2024 © AusnepIT Solutions.</div>
            </div>
        </>
    );
};

export default PrivacyPolicies;
