import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { store, persistor } from './redux/store.js'
import { PersistGate } from 'redux-persist/integration/react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC;
const queryClient = new QueryClient();
const stripePromise = loadStripe(stripeKey);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <GoogleOAuthProvider clientId="368723521694-u6df61d274d9rf6268j1pg3ka6fo2clu.apps.googleusercontent.com">
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>

              <BrowserRouter>
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
              </BrowserRouter>
            </PersistGate>

          </Provider>
        </QueryClientProvider>
      </GoogleOAuthProvider>;
    </Elements>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
