import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isSidebarClose } from '../../redux/index'
import { Overlay, Button, Accordion } from '../index'
import { scrollToTop } from '../../utils/scrollToTop'
import { IoMdClose } from "react-icons/io";
import { logoImg } from '../../assets/index'


const Sidebar = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const toggleSidebar = useSelector(state => state.sidebarReducer.isSidebarOpen)

    const loggedIn = true

    const navMenuList = [
        { id: 1, link: "/", label: "Home" },
        { id: 2, link: "/live-concerts", label: "Live Concerts" },
        { id: 3, link: "/seminar", label: "Seminar" },
        { id: 4, link: "/festivals", label: "Festivals" },
        { id: 5, link: "/marketing", label: "Marketing" },
        { id: 6, link: "/movies", label: "Movies" },
        { id: 7, link: "/others", label: "Others" },
        { id: 8, link: "/contact-Us", label: "Contact Us" },
    ]

    const nestedNavItems = [
        // { id: 1, label: "Profile", onClick: () => dispatch(isProfileOpen()) },
        { id: 1, label: "Profile", link: "/profile" },
        { id: 2, label: "History", link: "/history" }
    ]


    const buttonCss = 'px-4 py-3 font-medium text-xl rounded-lg text-left text-black whitespace-nowrap w-full bg-white hover:bg-paleBlue hover:text-primary'

    return (
        <>
            <aside
                className={`${toggleSidebar ? "translate-x-0" : "-translate-x-full"} 
                bg-white fixed left-0 top-0 pb-10 divide-y h-screen shadow-2xl transform z-50 ease-in-out duration-500
                w-[90%] md:w-2/3 lg:w-[40%] xl:w-1/4`}
            >

                <div className='h-[9vh] border-b flex items-center justify-between px-4 py-1'>

                    <div className="w-40 flex justify-center items-center gap-3">
                        <img src={logoImg} className='w-full h-full' alt="logo" />
                    </div>

                    <Button
                        onClick={() => dispatch(isSidebarClose())}
                        className={"py-4 pl-8 pr-0"}
                    >
                        <IoMdClose className='text-4xl' />
                    </Button>
                </div>


                {/* Menu Head */}
                <div className='h-[88vh] overflow-x-hidden overflow-y-auto'>

                    <div className='px-4 py-6 flex flex-col items-start justify-center my-2'>
                        {navMenuList.map((list) => (

                            <Button
                                key={list.id}
                                onClick={() => {
                                    navigate(`${list.link}`);
                                    scrollToTop();
                                    dispatch(isSidebarClose());
                                }}
                                className={`${buttonCss}`}
                                label={list.label}
                            />
                        ))}


                        {loggedIn &&
                            <Accordion
                                title={"Profile"}
                                className={"px-4 py-3 font-medium text-xl rounded-lg text-left text-black whitespace-nowrap w-full "}
                            >

                                {nestedNavItems?.map((item) => (

                                    <Button
                                        key={item.id}
                                        onClick={() => {
                                            item.onClick && item.onClick()
                                            item.link && navigate(`${item.link}`)
                                            scrollToTop()
                                            dispatch(isSidebarClose());

                                        }}
                                        className={buttonCss}
                                        label={item.label}
                                    />
                                ))}

                            </Accordion>
                        }

                    </div>
                </div>

            </aside>


            {toggleSidebar && (
                <Overlay onClick={() => dispatch(isSidebarClose())} />
            )}
        </>
    )
}

export default Sidebar
