import React from 'react'
import { useHistoryDetail } from '../../apis/PayApi';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import HisLoadSkeli from './HisLoadSkeli';


const HistoryDetail = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const { data: historyDetail } = useHistoryDetail(id);

    if (!historyDetail) return (<HisLoadSkeli />);

    const {
        id: transactionId,
        amount,
        created_at,
        status_type,
        user_info,
        booking_info,
        receipt_url,
    } = historyDetail;

    const {
        name,
        email,
        user_info: { address, phone, image_url }
    } = user_info;

    const {
        event_info: { title, start, organizer, venue, image_url: event_image },
    } = booking_info;



    return (
        <div className="edge-spacing bg-[#E0ECF9] py-12">

            <div className='grid grid-cols-1 md:grid-cols-2 gap-3 mb-3'>
                {/* User Info Section */}
                <div className="w-full bg-white shadow-md rounded p-4">
                    <div className="flex items-center">
                        <img src={image_url} alt="User" className="w-16 h-16 rounded-full mr-4" />
                        <div>
                            <h2 className="text-xl font-semibold">{name}</h2>
                            <p>{email}</p>
                        </div>
                    </div>
                    <div className="mt-4">
                        <p className='flex justify-center items-start'>
                            <strong className='w-1/2 lg:w-1/3'>Address:</strong>
                            <span className='w-1/2 lg:w-2/3'>{address}</span>
                        </p>
                        <p className='flex justify-center items-start'>
                            <strong className='w-1/2 lg:w-1/3'>Phone:</strong>
                            <span className='w-1/2 lg:w-2/3'>{phone}</span>
                        </p>
                    </div>
                </div>

                {/* Booking Info Section */}
                <div className="w-full bg-white shadow-md rounded p-4">
                    <div className="flex items-start w-full h-full justify-center flex-wrap lg:flex-nowrap gap-3">

                        <div className='w-full md:max-h-40 lg:w-44 md:aspect-square rounded overflow-hidden'>
                            <img src={event_image} alt="Event" className="w-full h-full object-cover" />
                        </div>

                        <div className='w-full'>
                            <h2 className="text-xl font-semibold pb-2">{title}</h2>
                            <p className='flex justify-center items-start'>
                                <strong className='w-1/2 lg:w-1/3'>Organizer:</strong>
                                <span className='w-1/2 lg:w-2/3'>{organizer}</span>
                            </p>
                            <p className='flex justify-center items-start'>
                                <strong className='w-1/2 lg:w-1/3'>Venue:</strong>
                                <span className='w-1/2 lg:w-2/3'>{venue}</span>
                            </p>
                            <p className='flex justify-center items-start'>
                                <strong className='w-1/2 lg:w-1/3'>Start Date:</strong>
                                <span className='w-1/2 lg:w-2/3'>{dayjs(start).format('MM/DD/YYYY h:mm A')}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='grid grid-cols-1 gap-3'>
                {/* Transaction Info Section */}
                <div className="bg-white shadow-md rounded p-4 w-full space-y-1">
                    <h3 className="text-lg font-semibold mb-2">Transaction Details</h3>
                    <p className='flex justify-center items-start'>
                        <strong className='w-1/2 md:w-1/3'>Transaction ID:</strong>
                        <span className='w-1/2 md:w-2/3'>{transactionId}</span>
                    </p>
                    <p className='flex justify-center items-start'>
                        <strong className='w-1/2 md:w-1/3'>Amount:</strong>
                        <span className='w-1/2 md:w-2/3'>${amount}</span>
                    </p>
                    <p className='flex justify-center items-start'>
                        <strong className='w-1/2 md:w-1/3'>Status:</strong>
                        <span className='w-1/2 md:w-2/3'>{status_type}</span>
                    </p>
                    <p className='flex justify-center items-start'>
                        <strong className='w-1/2 md:w-1/3'>Created At:</strong>
                        <span className='w-1/2 md:w-2/3'>{dayjs(created_at).format('MM/DD/YYYY h:mm A')}</span>
                    </p>
                    {receipt_url &&
                        <p className='flex justify-center items-start'>
                            <strong className='w-1/2 md:w-1/3'>Receipt:</strong>
                            <span className='w-1/2 md:w-2/3'>
                                <a className='text-primary' target='_blank' rel='noopener noreferrer' href={receipt_url}>View</a></span>
                        </p>
                    }
                </div>
            </div>

        </div>
    )
}

export default HistoryDetail;
