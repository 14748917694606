import React from 'react'
import { useParams } from 'react-router-dom'
import TicketInfo from './TicketInfo'
import { useCatDetail } from '../../apis/EventApi'
import { formatDate } from '../../utils/helpers'


// show event detail here too. and let user pick the ticket amount. and then we call event booking api 
const BookingProcess = () => {

    const { id } = useParams()


    const { data: catDetail } = useCatDetail(id);
    const catData = catDetail?.event_detail;


    return (
        <div className='edge-spacing bg-[#E0ECF9] py-12'>

            <div className='p-4 md:p-8 lg:p-12 space-y-4 bg-white md:w-[85%] m-auto rounded-md shadow-[0_0_10px_rgba(0,0,0,0.1)]'>

                <div className='font-medium text-2xl border-b py-3'>Book Event for You</div>

                <div className='pt-8 space-y-1 mb-4 w-full '>
                    <div className='font-medium'>Events</div>

                    <div className='font-semibold text-xl'>{catData?.title.toUpperCase()}</div>

                    <div className='text-sm'>Event Date: {formatDate(catData?.Date)}</div>
                </div>


                <TicketInfo id={id} />

            </div>
        </div>
    )
}

export default BookingProcess