import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosInstance } from './baseConfig';
import { useNavigate } from 'react-router-dom';
import { handleError, showToast } from '../utils/helpers';
import { useDispatch } from 'react-redux';
import { eventBookData } from '../redux/storage/action';
import { scrollToTop } from '../utils/scrollToTop';


const categories = async () => {
    const catUrl = `/categories`;
    const response = await axiosInstance.get(catUrl);
    return response.data;
};

export const useCategories = () => {
    return useQuery({
        queryFn: categories,
        queryKey: ["categories"],
    })
};

/*********************************************** ***********************************************/

const catDetail = async (id) => {
    const catDetailUrl = `/event-detail/${id}`;
    const response = await axiosInstance.get(catDetailUrl);
    return response.data;
};

export const useCatDetail = (id) => {
    return useQuery({
        queryFn: () => catDetail(id),
        queryKey: ["catDetail", id],
        enabled: !!id
    })
};

/*********************************************** ***********************************************/

const eventBook = async (body) => {
    const eventBookUrl = `/event-booking`;
    const response = await axiosInstance.post(eventBookUrl, body);
    return response.data;
};

export const useEventBook = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    return useMutation({
        mutationFn: eventBook,
        onSuccess: (data) => {
            showToast('success', data.message);
            dispatch(eventBookData(data))
            navigate(`/payment-process`);
            scrollToTop()
        },
        onError: (err) => {
            handleError(err);
        }
    });
};
