import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from "js-cookie";
import { Button, Modal, Overlay, OverlayLoading } from '../index'

import { useDispatch, useSelector } from 'react-redux'
import { navHeight, isSidebarOpen, isProfileClose } from '../../redux'

import ProfileHoverMenu from './ProfileHoverMenu'
import ProfileModalContent from './ProfileModalContent'

import { scrollToTop } from '../../utils/scrollToTop'
import useElementSize from '../../hooks/useElementSize'
import { useHoverMenu } from '../../hooks/useHoverMenu'

import { logoImg } from '../../assets'
import { FaSortDown, FaBars } from 'react-icons/fa6'
import { IoLogOut } from "react-icons/io5";
import { useLogOut } from '../../apis/AuthApi';


const Navbar = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const [isScrolling, setIsScrolling] = useState(false)
    const [openProfile, setOpenProfile] = useState(false)


    const toggleProfileModal = useSelector(state => state.profileModalReducer.isProfileModalOpen)

    const loggedIn = Cookies.get("access_token") ?? false

    const { mutate: logOutMutate, isPending: isLoggingOut } = useLogOut();

    const menuRef = useHoverMenu(setOpenProfile)
    const heightRef = useRef(null);
    const height = useElementSize(heightRef);

    useEffect(() => {
        dispatch(navHeight(height))
    }, [height, dispatch])


    const navItems = [
        { id: 1, link: "/", label: "Home" },
        { id: 2, link: "/live-concerts", label: "Live Concerts" },
        { id: 3, link: "/seminar", label: "Seminar" },
        { id: 4, link: "/festivals", label: "Festivals" },
    ]
    const navItems2 = [
        { id: 5, link: "/marketing", label: "Marketing" },
        { id: 6, link: "/movies", label: "Movies" },
        { id: 7, link: "/others", label: "Others" },
        { id: 8, link: "/contact-Us", label: "Contact Us" },
    ]

    const nestedNavItems = [
        {
            id: 9,
            label: "Profile",
            subCategory: [
                // { id: 1, label: "Profile", onClick: () => dispatch(isProfileOpen()) },
                { id: 1, label: "Profile", link: "/profile" },
                { id: 2, label: "History", link: "/history" }
            ]
        }
    ]


    const handleLogout = () => {
        logOutMutate()
    }

    return (
        <>
            {isLoggingOut &&
                <OverlayLoading />
            }


            <nav ref={heightRef} className={` px-6 py-4 text-white z-[35] bg-secondary fixed top-0 w-full flex items-center justify-between`}>
                <div className='w-fit flex justify-center items-center gap-x-4 gap-y-2'>
                    <Button
                        className={"w-36 flex-shrink-0"}
                        onClick={() => {
                            navigate("/")
                            scrollToTop()
                        }}
                    >
                        <img src={logoImg} className=' w-full h-full object-contain' alt="" />
                    </Button>

                    <div className='hidden lg:flex flex-wrap justify-center items-center'>
                        <>
                            <div className='flex justify-center items-center gap-2'>
                                {navItems?.map((item) => (
                                    <Button
                                        key={item.id}
                                        label={item.label}
                                        onClick={() => {
                                            navigate(`${item.link}`)
                                            scrollToTop()
                                        }}
                                        className={"px-2 py-2 text-sm hover:text-hover whitespace-nowrap"}
                                    />
                                ))}
                            </div>

                            <div className='flex justify-center items-center gap-2'>
                                {navItems2?.map((item) => (
                                    <Button
                                        key={item.id}
                                        label={item.label}
                                        onClick={() => {
                                            navigate(`${item.link}`)
                                            scrollToTop()
                                        }}
                                        className={"px-2 py-4 text-sm hover:text-hover whitespace-nowrap"}
                                    />
                                ))}


                                {loggedIn && nestedNavItems?.map((item) => (

                                    <div ref={menuRef} key={item.id} className='relative'>
                                        <Button
                                            key={item.id}
                                            onMouseEnter={() => setOpenProfile(true)}
                                            className={"px-2 py-4 text-sm flex items-start justify-center gap-1"}
                                        >
                                            <div>{item.label}</div>
                                            <FaSortDown />
                                        </Button>

                                        {openProfile &&
                                            <ProfileHoverMenu
                                                setOpenProfile={setOpenProfile}
                                                subCategory={item.subCategory}
                                            />
                                        }
                                    </div>
                                ))}
                            </div>

                        </>
                    </div>
                </div>

                {/* Right side content */}

                <div>
                    {loggedIn ?
                        <div className='hidden lg:flex items-center justify-center gap-6'>
                            {/* <Button
                                onClick={() => {
                                    navigate("/event-booking");
                                    scrollToTop()
                                }}
                                className={"px-4 py-2 lg:px-6 lg:py-2 bg-white text-black rounded whitespace-nowrap text-sm"}
                                label={"Book Now"}
                            /> */}
                            <Button
                                onClick={() => {
                                    handleLogout()
                                }}
                                className={"px-6 py-2 bg-white text-black rounded whitespace-nowrap text-sm"}
                                label={"Logout"}
                            />
                        </div>
                        :
                        <Button
                            onClick={() => {
                                navigate("/user/login");
                                scrollToTop()
                            }}
                            className={" px-3 py-2 md:px-8 md:py-3 border border-white hover:bg-white hover:text-black bg-secondary text-white rounded whitespace-nowrap text-sm"}
                            label={"Sign In / Register"}
                        />
                    }
                </div>

                {/* HamBar */}
                <div className='lg:hidden flex justify-center items-center gap-4'>

                    {loggedIn &&
                        <Button
                            onClick={handleLogout}
                        >
                            <IoLogOut className='text-3xl text-white' />
                        </Button>
                    }

                    <Button
                        onClick={() => dispatch(isSidebarOpen())}
                    >
                        <FaBars className='text-2xl text-white' />
                    </Button>

                </div>

            </nav>

            {/* ProfileModal */}
            {toggleProfileModal &&
                <Modal
                    title={"Your Profile"}
                    contents={<ProfileModalContent />}
                    openModal={() => dispatch(isProfileClose())}
                />
            }

            {toggleProfileModal && (
                <Overlay onClick={() => dispatch(isProfileClose())} />
            )}
        </>
    )
}

export default Navbar
