import React from 'react'
import Button from '../common/Button/Button'
import { IoIosMailOpen } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { scrollToTop } from '../../utils/scrollToTop';


const HomeFeedback = () => {

    const navigate = useNavigate()

    return (
        <div className='bg-customBlue edge-spacing py-12 text-white flex justify-evenly md:justify-between items-center gap-x-3 gap-y-8 flex-wrap'>

            <div className='flex items-start justify-center gap-6'>
                <IoIosMailOpen className='text-6xl md:text-7xl flex-shrink-0' />

                <div className='space-y-2'>
                    <div className='font-semibold text-2xl md:text-3xl'>Have any enquiries or Feedback??</div>
                    <div className=''>You can send us any queries or even feedback also</div>
                </div>
            </div>

            <Button
                onClick={() => {
                    scrollToTop()
                    navigate("/contact-us")
                }}
                className={"font-medium px-8 py-4 rounded bg-primary"}
                label={"Contact us"}
            />

        </div>
    )
}

export default HomeFeedback