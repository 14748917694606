import React from 'react'
import Button from '../common/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useHoverMenu } from '../../hooks/useHoverMenu'
import { scrollToTop } from '../../utils/scrollToTop';


const ProfileHoverMenu = ({ setOpenProfile, subCategory }) => {

    const menuRef = useHoverMenu(setOpenProfile)
    const navigate = useNavigate()


    return (
        <div ref={menuRef} className='arrow absolute p-4 z-40 left-0 bottom-0 translate-y-full bg-white shadow-[0_0_10px_rgba(0,0,0,0.1)] text-primary min-w-[240px] rounded'>
            {subCategory?.map((item) => (
                <Button
                    key={item.id}
                    onClick={() => {
                        item.onClick && item.onClick()
                        item.link && navigate(`${item.link}`)
                        scrollToTop()
                    }}

                    className={"hover:bg-paleBlue w-full px-4 py-2 rounded text-sm flex items-center justify-start gap-1"}
                    label={item.label}
                />
            ))}
        </div>
    )
}

export default ProfileHoverMenu