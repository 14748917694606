import { useState } from 'react';
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

const Accordion = ({ className, title, children }) => {

    const [activeIndex, setActiveIndex] = useState(false);

    return (
        <>
            <div className={`w-full`}>
                <div
                    className={`${className} ${activeIndex ? "bg-paleBlue" : ""}  transition-height transform ease-in-out duration-300 flex justify-between items-center cursor-pointer`}
                    onClick={() => setActiveIndex(!activeIndex)}
                >
                    <h3 className="font-medium text-xl ">{title}</h3>

                    {activeIndex ?
                        <IoIosArrowUp className='w-8 h-8 px-2' />
                        : <IoIosArrowDown className='w-8 h-8 px-2' />
                    }

                </div>

                <div className={` ${activeIndex ? "h-fit opacity-100" : "opacity-0 h-0"} pl-2 overflow-hidden transition-height transform ease-in-out duration-300`}>
                    {children}
                </div>
            </div >
        </>
    );
};

export default Accordion;
