import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosInstance, axiosTokenless } from './baseConfig';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { storeUserData } from '../redux/storage/action';
import Cookies from "js-cookie";
import { handleError, showToast } from '../utils/helpers';


// register
const registerUser = async (userData) => {
    const registerUrl = `/register`;
    const response = await axiosTokenless.post(registerUrl, userData);
    return response.data;
};

export const useRegister = () => {

    const navigate = useNavigate();

    return useMutation({
        mutationFn: registerUser,
        onSuccess: (data) => {
            showToast('success', data.message);
            const queryParams = new URLSearchParams();
            queryParams.set('id', data.user.id);
            queryParams.set('email', data.user.email);
            navigate(`/user/register/get-otp?${queryParams}`);
        },
        onError: (err) => {
            handleError(err);
        }
    });
};

/*********************************************** ***********************************************/

const verifyOtp = async (otpData) => {
    const verifyOtpUrl = `/verify-otp`;
    const response = await axiosTokenless.post(verifyOtpUrl, otpData);
    return response.data;
};

export const useVerifyOtp = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return useMutation({
        mutationFn: verifyOtp,
        onSuccess: (data) => {
            showToast('success', data.message);
            // Cookies.set("access_token", data.access_token)
            dispatch(storeUserData(data.user))
            navigate(`/user/register/reg-form`);
        },
        onError: (err) => {
            handleError(err);
        }
    });
};

/**
{
    "access_token": "eyJ0eXAiOiJKV1QiLCJhbG...",
    "token_type": "bearer",
    "expires_in": 1306368000000,
    "message": "OTP verified.",
    "user": {
        "id": 314,
        "name": null,
        "email": "civir82572@apn7.com",
        "email_verified_at": null,
        "role": "user",
        "status": "active",
        "created_at": "2024-07-09T07:33:07.000000Z",
        "updated_at": "2024-07-09T07:33:40.000000Z",
        "access_token": null,
        "referral_code": "ZeJYPq",
        "points": null,
        "otp": null,
        "otp_send": null,
        "otp_retry": null,
        "otp_blocked": null,
        "registration_type": "email",
        "google_id": null
    }
}
 */


/*********************************************** ***********************************************/

const saveRegister = async (regData) => {
    const saveRegUrl = `/save-register`;
    const response = await axiosTokenless.post(saveRegUrl, regData);
    return response.data;
};

export const useSaveRegister = () => {

    const navigate = useNavigate();

    return useMutation({
        mutationFn: saveRegister,
        onSuccess: (data) => {
            showToast('success', data.message);
            navigate(`/user/login`);
        },
        onError: (err) => {
            handleError(err);
        }
    });
};



// login
/*********************************************** ***********************************************/

const loginUser = async (loginData) => {
    const loginUrl = `/login`;
    const response = await axiosTokenless.post(loginUrl, loginData);
    return response.data;
};

export const useLogin = () => {

    const navigate = useNavigate();

    return useMutation({
        mutationFn: loginUser,
        onSuccess: (data) => {
            showToast('success', data.message);
            Cookies.set("access_token", data.access_token)
            navigate(`/`, { replace: true });
        },
        onError: (err) => {
            handleError(err);
        }
    });
};

/*********************************************** ***********************************************/


export const logOut = async () => {
    const logOutUrl = `/logout`;
    const response = await axiosInstance.get(logOutUrl);
    Cookies.remove("access_token")
    return response.data;
};

export const useLogOut = () => {

    const navigate = useNavigate();

    return useMutation({
        mutationFn: logOut,
        onSuccess: (data) => {
            showToast('success', data.message);
            Cookies.remove("access_token")
            navigate(`/`, { replace: true });
        },
        onError: (err) => {
            handleError(err);
        }
    });
};

/*********************************************** ***********************************************/


export const forgotPass = async (forgotPassBody) => {
    const forgotPassUrl = `/forget-password`;
    const response = await axiosTokenless.post(forgotPassUrl, forgotPassBody);
    return response.data;
};

export const useForgotPass = () => {

    const navigate = useNavigate();
    // issue: otp found in the response
    return useMutation({
        mutationFn: forgotPass,
        onSuccess: (data) => {
            showToast('success', data.message);
            if (data?.message === "User not exists") { return }
            else { navigate(`/password/reset/otp?id=${data?.user?.id}&email=${data?.user?.email}`, { replace: true }); }
        },
        onError: (err) => {
            handleError(err);
        }
    });
};

/*********************************************** ***********************************************/

export const confirmOtp = async (body) => {
    const url = `/confirm-otp`;
    const response = await axiosTokenless.post(url, body);
    return response.data;
};

export const useConfirmOtp = () => {

    const navigate = useNavigate();

    return useMutation({
        mutationFn: confirmOtp,
        onSuccess: (data) => {
            showToast('success', data.message);
            navigate(`/password/reset?email=${data?.user?.email}`, { replace: true });
        },
        onError: (err) => {
            handleError(err);
        }
    });
};

/*********************************************** ***********************************************/


export const passUpdate = async (body) => {
    const url = `/password-update`;
    const response = await axiosTokenless.post(url, body);
    return response.data;
};

export const usePassUpdate = () => {

    const navigate = useNavigate();

    return useMutation({
        mutationFn: passUpdate,
        onSuccess: (data) => {
            showToast('success', data.message);
            navigate(`/user/login`, { replace: true });
        },
        onError: (err) => {
            handleError(err);
        }
    });
};

/**
 * turns out "/password-update" works only after otp verification so it's safe.
 */

/*********************************************** ***********************************************/

// where to use?
// const sendOtp = async (otpData) => {
//     const sendOtpUrl = `/send-otp`;
//     const response = await axiosTokenless.post(sendOtpUrl, otpData);
//     return response.data;
// };

// export const useSendOtp = () => {
//     return useMutation({
//         mutationFn: sendOtp,
//     });
// };

/*********************************************** ***********************************************/

const socialLogin = async (loginData) => {
    const socialLoginUrl = `/social_login`;
    const response = await axiosTokenless.post(socialLoginUrl, loginData);
    return response.data;
};

export const useSocialLogin = () => {

    const navigate = useNavigate();

    return useMutation({
        mutationFn: socialLogin,
        onSuccess: (data) => {
            showToast('success', data.message);
            Cookies.set("access_token", data.access_token)
            navigate(`/`, { replace: true });
        },
        onError: (err) => {
            handleError(err);
        }
    });
};


/***************************************** PROFILE ***********************************************/

const getProfile = async () => {
    const catUrl = `/profile`;
    const response = await axiosInstance.get(catUrl);
    return response.data;
};

export const useGetProfile = () => {
    return useQuery({
        queryFn: getProfile,
        queryKey: ["getProfile"],
    })
};


/*********************************************** ***********************************************/

export const uploadImg = async (body) => {
    const url = `/profile-image`;
    const response = await axiosInstance.post(url, body);
    return response.data;
};

export const useUploadImg = () => {

    return useMutation({
        mutationFn: uploadImg,
        onSuccess: (data) => {
            showToast('success', data.message);
        },
        onError: (err) => {
            handleError(err);
        }
    });
};

/*********************************************** ***********************************************/

export const updateProfile = async (body) => {
    const url = `/profile-update`;
    const response = await axiosInstance.post(url, body);
    return response.data;
};

export const useUpdateProfile = () => {

    return useMutation({
        mutationFn: updateProfile,
        onSuccess: (data) => {
            showToast('success', data.message);
        },
        onError: (err) => {
            handleError(err);
        }
    });
};

/**rexave4306@leacore.com
 * Shah@123
 * Hello@123
 */

