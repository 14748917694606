import React, { useEffect } from 'react'
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import Cookies from "js-cookie";
import { Button, Table } from '../../components';
import { scrollToTop } from '../../utils/scrollToTop.js'
import { useCatDetail } from '../../apis/EventApi.js';
import { formatDate } from '../../utils/helpers.js';



const Concert = () => {

    const navigate = useNavigate();
    const loggedIn = Cookies.get("access_token") ?? false

    /************************************ Custom Breadcrumb ***********************************/

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const label = searchParams.get('lbl');
    const [, setCustomPath] = useOutletContext();

    useEffect(() => {
        const path = [{ label: label, url: `/concert/${label}` }]
        setCustomPath(path);

        return () => { setCustomPath(null); };
    }, [setCustomPath, label]);


    /******************************************** *******************************************/

    const { data: catDetail } = useCatDetail(id);
    const catData = catDetail?.event_detail;
    const locData = catData?.venue_info;
    const artistData = catData?.artist_info;
    const ticketData = catData?.ticket_info;
    const category = catData?.cat_info;


    const tickteTableBody = ticketData?.map(val => [
        val?.kind_info?.title,
        `$${val?.price}`,
        val?.remaining === 0 ? <p className='text-red-500'>Sold Out</p> : `${val?.remaining} tickets`,
    ])

    const isSoldOut = ticketData?.every(val => val?.remaining === 0);

    // const tickteTableBody = [
    //     ["Adult", "$59.99"],
    //     ["Free", "Free"],
    // ]

    /******************************************************************************************/


    const handleBooking = () => {
        scrollToTop()
        navigate(`/booking-process/${id}`)
    }

    return (
        <div className='edge-spacing pt-12 pb-4 flex flex-col lg:flex-row justify-center items-start gap-6'>

            {/* left side content */}
            <div className='w-full lg:w-2/3 divide-y'>

                <section className='pb-9'>
                    <div className='rounded overflow-hidden h-[20rem]'>
                        <img src={catData?.image_url} className='w-full h-full object-contain' alt="" />
                    </div>

                    <h1 className='text-2xl py-8 font-semibold'>{catData?.title.toUpperCase()}</h1>

                    <div className='space-y-2 mb-4 text-customGray text-sm'>
                        <div>Venue: {catData?.venue}</div>
                        <div>Date: {formatDate(catData?.Date)}</div>
                    </div>

                    {loggedIn ?
                        isSoldOut ?
                            <Button
                                disabled={true}
                                className={"mt-2 border font-medium px-24 rounded py-4 bg-primary text-white hover:bg-[#051036]"}
                                label={"Out of Stock"}
                            />
                            :
                            <Button
                                onClick={handleBooking}
                                className={"mt-2 font-medium px-24 rounded py-4 bg-primary text-white hover:bg-[#051036]"}
                                label={"Book Now"}
                            />
                        :
                        <Button
                            onClick={() => navigate("/user/login")}
                            className={"mt-2 font-medium px-24 rounded py-4 bg-primary text-white hover:bg-[#051036]"}
                            label={"Login To Book"}
                        />
                    }
                </section>


                <section className='py-12'>
                    <p
                        className="text-base text-secondary text-justify"
                        dangerouslySetInnerHTML={{ __html: catData?.detail }}
                    />
                </section>

                <section className='pt-12 space-y-4'>

                    <h2 className='text-xl font-medium'>Ticket Availability</h2>

                    <Table
                        headItems={["Ticket Kind", "Price (AUD)", "Remaining tickets"]}
                        bodyItems={tickteTableBody}
                        bodyCss={"border-primary"}
                        colWidth={["w-1/3", "w-1/3", "w-1/3"]}
                    />

                    <p className='py-8'>* 0% admin fee will be charged while purchasing of ticket.</p>

                    <div className='bg-[#EBFCEA] w-full rounded px-6 py-5'>Enjoy By booking events on time</div>
                </section>

            </div>


            {/* Right side content */}
            <div className='w-full lg:w-1/3 space-y-6'>

                <div className='w-full rounded border p-4 lg:p-6 divide-y'>
                    <div className='font-medium py-4'>Location Detail</div>

                    <div className='py-4 text-sm space-y-3'>
                        <div className='flex flex-col justify-start items-start gap-1'>
                            <div>Venue: </div>
                            <span className='text-customGray'>{locData?.venue}</span>
                        </div>
                        <div className='flex flex-col justify-start items-start gap-1'>
                            <div>Address: </div>
                            <span className='text-customGray'>{locData?.address}</span>
                        </div>
                        <div className='flex flex-col justify-start items-start gap-1'>
                            <div>Contact: </div>
                            <span className='text-customGray'>{locData?.contact_phone}</span>
                        </div>
                        <div className="flex flex-col justify-start items-start gap-1">
                            <div>Email:</div>
                            <a href={`mailto:${locData?.contact_email}`} className="text-primary">
                                {locData?.contact_email}
                            </a>
                        </div>
                        <div className='flex flex-col justify-start items-start gap-1'>
                            <div>Website: </div>
                            <a target='_blank' rel="noreferrer" href={locData?.contact_website} className='text-primary'>{locData?.contact_website}</a>
                        </div>
                    </div>
                </div>

                <div className='w-full rounded border p-4 lg:p-6 divide-y'>
                    <div className='font-medium py-4'>Event Info</div>

                    <div className='py-4 text-sm space-y-3'>
                        <div className='flex justify-start items-start gap-2'>
                            <div>Organizer: </div>
                            <span className='text-customGray'>{catData?.organizer}</span>
                        </div>
                        <div className='flex justify-start items-start gap-2'>
                            <div>Genre: </div>
                            <span className='text-customGray'>{category?.title}</span>
                        </div>
                    </div>
                </div>

                <div className='w-full rounded border p-4 lg:p-6 divide-y'>
                    <div className='font-medium py-4'>Artists</div>

                    <div className='grid gap-3 grid-cols-[repeat(auto-fill,minmax(7rem,1fr))]'>
                        {artistData?.map(val => (
                            <div key={val.id} className='pt-4 lg:pt-6 text-sm space-y-3 flex flex-col justify-center items-center w-full'>
                                <div className='aspect-square w-24 max-w-full rounded-full overflow-hidden border'>
                                    <img src={val?.image_url} className='w-full h-full object-cover' alt="artist" />
                                </div>
                                <div>{val?.name}</div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Concert