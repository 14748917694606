import React, { Fragment, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import useWindowResolution from '../../hooks/useWindowResolution';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className}`}
            style={{
                ...style,
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                height: "fit-content",
                width: "49px",
                transform: "translate(200%, 25%)",
                right: "50%",
                bottom: 0,
                top: "unset",
                zIndex: 1
            }}
            onClick={onClick}
        >
            <div className='group aspect-square rounded-full w-full relative bg-white duration-300 block'>
                <IoIosArrowRoundForward className='group-hover:text-primary group-hover:scale-150 text-customGray duration-150 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-3xl' />
            </div>
        </div>

    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className}`}
            style={{
                ...style,
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                height: "fit-content",
                width: "49px",
                transform: "translate(-200%, 25%)",
                left: "50%",
                bottom: 0,
                top: "unset",
                zIndex: 1
            }}
            onClick={onClick}
        >
            <div className='group aspect-square rounded-full w-full relative bg-white duration-300 block'>
                <IoIosArrowRoundBack className='group-hover:text-primary group-hover:scale-150 text-customGray duration-150 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-3xl' />
            </div>
        </div>
    );
}

const ItemsCarousel = ({
    contents = [],
    className,
}) => {


    const windowSize = useWindowResolution();
    const { width: winWidth } = windowSize;
    const sliderRef = useRef(null);

    const responsiveSlides = {
        '2xl': { breakpoint: 1921, slidesToShow: 4 },
        'xl': { breakpoint: 1377, slidesToShow: 4 },
        'lg': { breakpoint: 1025, slidesToShow: 3 },
        'md': { breakpoint: 767, slidesToShow: 1 },
    };

    const {
        '2xl': { slidesToShow: xxl, breakpoint: break1 },
        'xl': { slidesToShow: xl, breakpoint: break2 },
        'lg': { slidesToShow: lg, breakpoint: break3 },
        'md': { slidesToShow: md, breakpoint: break4 },
    } = responsiveSlides;

    // Slides limit should be greater than max slidesToShow
    const atLeast = responsiveSlides['2xl'].slidesToShow + 1
    const limitSlides = contents.slice(0, atLeast + 2);



    let useCarouselOrNot

    function calculateUse(res) {
        if (limitSlides.length > res) { useCarouselOrNot = true }
        else (useCarouselOrNot = false)
    }

    if (winWidth <= break4) { calculateUse(md) }
    if (winWidth <= break3 && winWidth > break4) { calculateUse(lg) }
    if (winWidth <= break2 && winWidth > break3) { calculateUse(xl) }
    if (winWidth <= break1 && winWidth > break2) { calculateUse(xxl) }


    const settings = {
        dots: true,
        infinite: limitSlides.length > xxl,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        swipeToSlide: limitSlides.length > xxl,
        responsive: [
            {
                breakpoint: break1,
                settings: {
                    slidesToShow: xxl,
                    infinite: limitSlides.length > xxl,
                }
            },
            {
                breakpoint: break2,
                settings: {
                    slidesToShow: xl,
                    infinite: limitSlides.length > xl,
                }
            },
            {
                breakpoint: break3,
                settings: {
                    slidesToShow: lg,
                    infinite: limitSlides.length > lg,
                }
            },
            {
                breakpoint: break4,
                settings: {
                    slidesToShow: md,
                    infinite: limitSlides.length > md,
                }
            }
        ]
    };


    const disable = useRef(false)
    const [isMouseMoving, setIsMouseMoving] = useState(true);
    const [isMouseDown, setIsMouseDown] = useState(false)

    useEffect(() => {
        if (isMouseDown && isMouseMoving) { disable.current = true }
        else if (!isMouseDown) disable.current = false

    }, [isMouseDown, isMouseMoving])


    const handleMouseMove = () => {
        if (isMouseDown) {
            setIsMouseMoving(true);
        }

        const timeoutId = setTimeout(() => {
            setIsMouseMoving(false)
        }, 500);
        return () => clearTimeout(timeoutId);
    };

    const handleMouseDown = () => {
        setIsMouseDown(true)
    }

    const condition = !isMouseMoving && isMouseDown && !disable.current

    return (
        <>
            <style className='hidden'>
                {`
                .slick-arrow::before {
                    content: none;
                }
                .slick-dots {
                    position: static;
                }
                `}
            </style>


            {useCarouselOrNot ?
                <>

                    <Slider
                        ref={(slider) => {
                            if (slider) {
                                sliderRef.current = slider
                            }
                        }}
                        {...settings}
                        className={`relative`}
                    >
                        {limitSlides?.map((content, index) => (
                            <div key={index} className={`${className}`}>
                                {React.cloneElement(content, {
                                    isMouseMoving,
                                    handleMouseDown,
                                    handleMouseMove,
                                    setIsMouseDown,
                                    setIsMouseMoving,
                                    isMouseDown,
                                    condition
                                })}
                            </div>
                        ))}
                    </Slider>
                </>
                :
                <div className='grid gap-3 gap-y-10 md:gap-x-6 grid-cols-[repeat(auto-fill,minmax(170px,1fr))] lg:grid-cols-[repeat(auto-fill,minmax(230px,1fr))]'>
                    {limitSlides?.map((content, index) => (
                        <div key={index} className={`${className}`}>
                            {React.cloneElement(content, {
                                isMouseMoving,
                                handleMouseDown,
                                handleMouseMove,
                                setIsMouseDown,
                                setIsMouseMoving,
                                isMouseDown,
                                condition
                            })}
                        </div>
                    ))}
                </div>
            }
        </>
    );
};

export default ItemsCarousel;
