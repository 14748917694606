import React from 'react'
import { homeBG_Img, } from '../../assets/index'
import {
    Button,
    RevealOnScroll,
    PopularEventsCard,
    FeaturedEventsCard,
    ItemsCarousel,
    ItemsCarouselHome,
    CatLoading,
    Cat2Loading
} from '../../components/index'

import { GoArrowUpRight } from "react-icons/go";
import './Home.css'
import { useNavigate } from 'react-router-dom';
import { scrollToTop } from '../../utils/scrollToTop';
import { useCategories } from '../../apis/EventApi';
import { formatDate } from '../../utils/helpers';
import { eventView } from '../../shared/constants';



const Home = () => {

    const navigate = useNavigate()

    const { data: catList } = useCategories();
    const catData = catList?.categories;

    const featureCat = catData?.filter(data => data.status === "active").find(val => val.title === "featured")?.event_info;
    const popularCat = catData?.filter(data => data.status === "active").find(val => val.title === "popular")?.event_info;


    /** 
     * Test data
     * 
    const popularEvents = [
        { id: 1, image: homeBG_Img, label: "Captain Sparrow And the Pharroh", date: "9:11 am, 11 Sept, 2001" },
        { id: 2, image: homeBG_Img, label: "Lost Between Towers: A Pilot's Tale of Survival", date: "9:11 am, 11 Sept, 2001" },
        { id: 3, image: homeBG_Img, label: "Sabin Sparrow And the Pharroh", date: "9:11 am, 11 Sept, 2001" },
        { id: 4, image: homeBG_Img, label: "Captain Sparrow And the Sabin", date: "9:11 am, 11 Sept, 2001" },
        { id: 5, image: homeBG_Img, label: "Captain Sparrow And the Sabin", date: "9:11 am, 11 Sept, 2001" },
    ]

    const featuredEvents = [
        { id: 1, image: homeBG_Img, label: "Captain Sparrow And the Pharroh", location: "Metro Theatre, Sydney, NSW" },
        { id: 2, image: homeBG_Img, label: "Lost Between Towers: A Pilot's Tale of Survival", location: "Kwong-Im Temple" },
        { id: 3, image: homeBG_Img, label: "Sabin Sparrow And the Pharroh", location: "Metro Theatre, Sydney, NSW" },
        { id: 4, image: homeBG_Img, label: "Captain Sparrow And the Sabin", location: "Metro Theatre, Sydney, NSW" },
        { id: 5, image: homeBG_Img, label: "Captain Sparrow And the Sabin", location: "Metro Theatre, Sydney, NSW" },
    ]
    */


    return (
        <div>
            <section className='relative w-full h-96 lg:h-[89vh]'>
                <div className='z-[5] w-full h-full absolute bg-gradient-to-r from-navyBlueFrom from from-10% to-navyBlueTo'></div>
                <div className='animate-fade-in-absolute initial-position absolute z-10 whitespace-nowrap text-white font-bold text-4xl md:text-6xl top-1/2 left-1/2'>OLA Ticket</div>
                <img src={homeBG_Img} className='w-full h-full object-cover' alt="" />
            </section>

            <RevealOnScroll>
                <section className='edge-spacing py-12 space-y-8'>

                    <div className='flex flex-wrap justify-around md:justify-between items-center'>
                        <h2 className='title'>Popular Events</h2>

                        <Button
                            onClick={() => {
                                scrollToTop()
                                navigate(`/all-events?view=${eventView.pop}`)
                            }}
                            className={"px-4 py-2 md:px-7 md:py-3 text-primary hover:text-white rounded-md bg-paleBlue hover:bg-hover flex justify-center items-center gap-2"}
                        >
                            <div>View All</div>
                            <GoArrowUpRight className='text-xl md:text-2xl' />
                        </Button>
                    </div>

                    {!catList ?
                        <CatLoading /> :
                        <ItemsCarouselHome
                            className={"px-3 py-6"}
                            contents={popularCat?.map((val) => (
                                <PopularEventsCard
                                    key={val.id}
                                    id={val.id}
                                    image={val.image_url}
                                    label={val.title}
                                    date={formatDate(val.start)}
                                />
                            ))}
                        />
                    }
                </section>
            </RevealOnScroll>

            <RevealOnScroll>

                <section className='edge-spacing py-12 space-y-8'>
                    <div className='flex flex-wrap justify-around items-start md:justify-between md:items-center'>

                        <div className='space-y-3'>
                            <h2 className='title'>Featured Events</h2>
                            <div className='text-customGray'>Most Featured Events</div>
                        </div>

                        <Button
                            onClick={() => {
                                scrollToTop()
                                navigate(`/all-events?view=${eventView.feat}`)
                            }}
                            className={"px-4 py-2 md:px-7 md:py-3 text-primary hover:text-white rounded-md bg-paleBlue hover:bg-hover flex justify-center items-center gap-2"}
                        >
                            <div>View All</div>
                            <GoArrowUpRight className='text-xl md:text-2xl' />
                        </Button>
                    </div>

                    {!catList ?
                        <Cat2Loading /> :
                        <ItemsCarousel
                            className={"px-3 py-6"}
                            contents={featureCat?.map((val) => (
                                <FeaturedEventsCard
                                    key={val.id}
                                    id={val.id}
                                    image={val.image_url}
                                    label={val.title}
                                    location={val.venue}
                                />
                            ))}
                        />
                    }
                </section>
            </RevealOnScroll>

        </div>
    )
}

export default Home