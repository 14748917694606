import React from 'react'
import { guraenteeImg, bookingImg, customerCareImg } from '../../assets/index'
import { RevealOnScroll } from '../index'

const SecondFooter = () => {


    const contents = [
        { id: 1, image: guraenteeImg, label: "Best Price Guarantee" },
        { id: 2, image: bookingImg, label: "Easy & Quick Booking" },
        { id: 3, image: customerCareImg, label: "Customer Care 24/7" },
    ]


    return (
        <div className='pt-24'>
            <RevealOnScroll>
                <div className='edge-spacing text-secondary pb-24'>
                    <div className='flex flex-col md:flex-row gap-x-3 gap-y-6 justify-around items-center'>
                        {contents?.map((item) => (
                            <div key={item.id} className='flex flex-col justify-center items-center gap-6'>
                                <div className='aspect-square w-20'>
                                    <img src={item.image} className='w-full h-full object-contain' alt="" />
                                </div>

                                <p className='whitespace-nowrap font-semibold text-lg'>{item.label}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </RevealOnScroll >
        </div>
    )
}

export default SecondFooter