import React from 'react'
import { Button, OverlayLoading, Table } from '../../components'
import { useSelector } from 'react-redux';
import CheckoutForm from './CheckoutForm';
import { usePaymentApi } from '../../apis/PayApi';
import { useGetProfile } from '../../apis/AuthApi';
import dayjs from 'dayjs';

const PaymentDetail = () => {


    const catDetail = useSelector(state => state.storageReducer.eventBookData)
    const bookData = catDetail?.booking_detail;


    const { data: catList } = useGetProfile();
    const info = catList?.user_detail;

    const userData = {
        id: info?.id,
        image: info?.image_url,
        name: info?.name,
        email: info?.email,
        contact: info?.user_info?.phone,
        address: info?.user_info?.address,
    }

    const { mutate: payMutate, isPending } = usePaymentApi();


    const tableData = bookData?.booking_ticket?.map(val => {
        if (val?.total_ticket === 0) { return false }
        const qty = val?.total_ticket;
        const kind = val?.ticket_info?.kind_info?.title;
        const cost = !val.ticket_info?.remaining ? <p className='text-red-500'>Sold Out</p> : `$${val?.ticket_info?.price}`;

        const costDisplay = (cost === 0) ? "Free" : cost;
        return [kind, qty, costDisplay];
    }).filter(Boolean);


    const totalTickets = bookData?.total_ticket;
    const totalCost = bookData?.sub_total;
    const serviceFee = bookData?.service_fee;
    const discountAmt = bookData?.discount_amount;
    const couponCode = bookData?.coupon_code;
    const gstAmount = bookData?.gst_amt;

    const displayData = [
        { id: 1, label: "Total Number of Tickets:", value: totalTickets },
        couponCode && { id: 2, label: "Coupon Code:", value: couponCode },
        { id: 3, label: "Discount Amount:", value: `$${discountAmt}` },
        { id: 4, label: "Service fee (0%):", value: `$${serviceFee}` },
        { id: 5, label: "Total Paying amount:", value: `$${totalCost}` },
        { id: 6, label: "Including GST of:", value: `$${gstAmount?.toFixed(2)}` },
    ].filter(Boolean);

    const formattedDate = dayjs().format('YYYY-MM-DD HH:mm:ss');

    const handleFreeBook = () => {
        const payBody = {
            "event_id": bookData?.event_id,
            "coupon_code": couponCode,
            "ticket_detail": bookData?.ticket_detail,
            "total_ticket": totalTickets,
            "booking_ticket": bookData?.booking_ticket?.map(val => val.id),
            "discount_amount": discountAmt,
            "price": totalCost,
            "gst_amt": gstAmount,
            "payed_amt": 0,
            "date": formattedDate,
            "user_id": userData?.id,
            "transactionId": null,
            "paymentMethod": null,
            "receiptUrl": null
        }

        payMutate(payBody)
    }

    return (
        <>
            {isPending &&
                <OverlayLoading />
            }

            <div className='space-y-8'>
                <div className='w-full rounded border p-4 divide-y'>
                    <div className='font-medium py-4'>Payment Detail</div>

                    <div className='space-y-1 py-4'>
                        <div className='text-sm'>Tickets</div>
                        <Table
                            headItems={["Ticket Kind", "Qty", "Price (AUD)"]}
                            bodyItems={tableData}
                            colWidth={["w-[40%]", "w-[20%]", "w-[40%]"]}
                            cellPadding={'py-1 px-2'}
                            mainCss={""}
                        />

                        <div className='pt-2 divide-y'>
                            {displayData?.map((data) => (
                                <div key={data.id} className='py-2 flex justify-between items-center gap-2 text-sm'>
                                    <div>{data.label}</div>
                                    <div className='text-customGray font-semibold'>{data.value}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {totalCost !== 0 ?
                    <CheckoutForm /> :

                    <Button
                        onClick={handleFreeBook}
                        className="mt-2 bg-primary px-4 py-3 text-white rounded"
                        label={`Book Now`}
                    />
                }

            </div>
        </>
    )
}

export default PaymentDetail