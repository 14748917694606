import React from 'react'

const CatLoading = () => {
    return (
        <div className='px-3 py-6 grid gap-3 gap-y-10 md:gap-x-6 grid-cols-[repeat(auto-fill,minmax(170px,1fr))] lg:grid-cols-[repeat(auto-fill,minmax(230px,1fr))]'>
            <div className='animate-pulse aspect-[9/11] rounded-md bg-gray-200 flex flex-col justify-end items-center p-5 gap-3'>
                <div className='z-10 bg-slate-300 animate-pulse h-3 w-full rounded-md'></div>
                <div className='z-10 bg-slate-300 animate-pulse h-3 w-full rounded-md'></div>
                <div className='z-10 bg-slate-300 animate-pulse h-3 w-full rounded-md'></div>
            </div>
            <div className='animate-pulse aspect-[9/11] rounded-md bg-gray-200 flex flex-col justify-end items-center p-5 gap-3'>
                <div className='z-10 bg-slate-300 animate-pulse h-3 w-full rounded-md'></div>
                <div className='z-10 bg-slate-300 animate-pulse h-3 w-full rounded-md'></div>
                <div className='z-10 bg-slate-300 animate-pulse h-3 w-full rounded-md'></div>
            </div>
            <div className='animate-pulse aspect-[9/11] rounded-md bg-gray-200 flex flex-col justify-end items-center p-5 gap-3'>
                <div className='z-10 bg-slate-300 animate-pulse h-3 w-full rounded-md'></div>
                <div className='z-10 bg-slate-300 animate-pulse h-3 w-full rounded-md'></div>
                <div className='z-10 bg-slate-300 animate-pulse h-3 w-full rounded-md'></div>
            </div>
        </div>
    )
}

export default CatLoading