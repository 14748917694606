import React from 'react'
import { EventBody } from '../../components'
import { useCategories } from '../../apis/EventApi'


const Festivals = () => {

    const { data: catList } = useCategories();
    const catData = catList?.categories;

    const festivalCat = catData?.filter(data => data.status === "active").find(val => val.title === "Festivals")?.event_info;


    return (
        <>
            <EventBody catArr={festivalCat} label={"Festivals"} />
        </>
    )
}

export default Festivals