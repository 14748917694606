import React, { useState } from 'react'
import { Button, OverlayLoading, PasswordField, TextField } from '../../../components'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate } from 'react-router-dom'
import { useSaveRegister } from '../../../apis/AuthApi'
import { useSelector } from 'react-redux'
import { scrollToTop } from '../../../utils/scrollToTop'


const RegisterValidationSchema = Yup.object({
    full_name: Yup.string().required("Please enter your full name"),
    email: Yup.string().email().required("Please enter your email"),
    address: Yup.string(),
    phone: Yup.string(),
    // phone: Yup.string().matches(/^(0[2-478])(\d{8})$/, "Please enter a valid phone number"),
    password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one symbol")
        .required("Please enter your password"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords must match")
        .required("Please confirm your password"),
    terms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')
})


const RegForm = () => {

    const navigate = useNavigate()


    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);


    const { mutate: saveRegMutate, isPending: isRegistering } = useSaveRegister();

    const userData = useSelector(state => state.storageReducer.userData)


    const onFormSubmit = (val, action) => {
        saveRegMutate({
            ...val,
            "user_id": userData.id
        })
        action.resetForm()
    }

    const initialValues = {
        full_name: "",
        address: "",
        phone: "",
        email: userData.email,
        password: "",
        password_confirmation: "",
        terms: false
    }

    return (
        <>
            {isRegistering &&
                <OverlayLoading />
            }

            <div className='bg-[#E5F0FD] py-12 edge-spacing'>

                <Formik
                    initialValues={initialValues}
                    onSubmit={onFormSubmit}
                    validationSchema={RegisterValidationSchema}
                >
                    {() => (
                        <Form className='p-4 md:p-8 lg:p-12 space-y-4 bg-white md:w-[80%] lg:w-1/2 m-auto rounded-md shadow-[0_0_10px_rgba(0,0,0,0.1)]'>

                            <div className='font-medium text-xl'>Sign in or create an account</div>

                            <div className='flex items-center justify-start gap-1'>

                                <div className='text-customGray'>Already have an account?</div>
                                <Button
                                    onClick={() => navigate("/user/login")}
                                    className={"text-primary"}
                                    label={"Log in"}
                                />

                            </div>

                            <TextField
                                label="Full Name *"
                                name="full_name"
                                type="text"
                            />

                            <TextField
                                label="Address"
                                name="address"
                                type="text"
                            />

                            <TextField
                                label="Contact No"
                                name="phone"
                                type="number"
                            />

                            <TextField
                                label="Email"
                                name="email"
                                type="email"
                                disabled={true}
                                focus={true}
                            />

                            <PasswordField
                                label="Password"
                                name="password"
                                type={`${showPassword ? "text" : "password"}`}
                                setShowPassword={setShowPassword}
                                showPassword={showPassword}
                            />

                            <PasswordField
                                label="Confirm Password *"
                                name="password_confirmation"
                                type={`${showConfirmPassword ? "text" : "password"}`}
                                setShowPassword={setShowConfirmPassword}
                                showPassword={showConfirmPassword}
                            />

                            <div className='flex items-center'>

                                <Field
                                    id={"terms"}
                                    type="checkbox"
                                    name="terms"
                                />

                                <label className='ml-2 text-sm flex justify-start items-center gap-1' htmlFor="terms">
                                    <Link to={"/privacy-policies"} onClick={scrollToTop}>
                                        <p className="hover:text-hover hover:underline">
                                            Privacy Policy
                                        </p>
                                    </Link>
                                    &
                                    <Link to={"/privacy-policies"} onClick={scrollToTop}>
                                        <p className="hover:text-hover hover:underline">
                                            Terms and conditions
                                        </p>
                                    </Link>
                                </label>
                            </div>
                            <ErrorMessage name="terms" component="div" className="text-red-500 text-sm" />

                            <Button
                                className='mt-8 text-sm font-medium rounded-md text-white px-6 py-3 bg-primary'
                                type='submit'
                                label={'SIGN UP'}
                            />
                        </Form>
                    )}
                </Formik>

            </div>
        </>
    )
}

export default RegForm