import { combineReducers } from "redux";
import sidebarReducer from './sidebar/reducer'
import navHeightReducer from './navHeight/reducer'
import profileModalReducer from './modal/reducer'
import storageReducer from './storage/reducer'

const rootReducer = combineReducers({
    sidebarReducer: sidebarReducer,
    navHeightReducer: navHeightReducer,
    profileModalReducer: profileModalReducer,
    storageReducer: storageReducer
})

export default rootReducer