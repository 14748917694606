import React, { useEffect, useState } from 'react'
import { Button, OverlayLoading, TextField } from '../../../components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useRegister, useVerifyOtp } from '../../../apis/AuthApi'
import { useDispatch } from 'react-redux'
import { cleanUserData } from '../../../redux/storage/action'


const RegisterValidationSchema = Yup.object({
    otp: Yup.string(),
})

const initialValues = {
    otp: ""
}

const GetOtp = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams()

    const id = searchParams.get('id')
    const email = searchParams.get('email')

    const { mutate: verifyMutate, isPending: isVerifying } = useVerifyOtp();
    const { mutate: registerMutate, isPending: isRegistering } = useRegister();

    const [isResendDisabled, setIsResendDisabled] = useState(true)
    const [timer, setTimer] = useState(60)

    const handleResend = () => {
        registerMutate({ email: email })
        setIsResendDisabled(true)
        setTimer(60)
    }

    useEffect(() => {
        let interval;
        if (isResendDisabled) {
            interval = setInterval(() => {
                setTimer(prev => {
                    if (prev === 1) {
                        clearInterval(interval)
                        setIsResendDisabled(false)
                        return 0
                    }
                    return prev - 1
                })
            }, 1000)
        }
        return () => clearInterval(interval)
    }, [isResendDisabled])

    const onFormSubmit = (val, action) => {
        verifyMutate({
            ...val,
            "user_id": id
        })

        dispatch(cleanUserData())
        action.resetForm()
    }

    return (
        <>
            {isVerifying &&
                <OverlayLoading />
            }

            <div className='bg-[#E5F0FD] py-12 edge-spacing'>

                <Formik
                    initialValues={initialValues}
                    onSubmit={onFormSubmit}
                    validationSchema={RegisterValidationSchema}
                >
                    {() => (
                        <Form className='p-4 md:p-8 lg:p-12 space-y-4 bg-white md:w-[80%] lg:w-1/2 m-auto rounded-md shadow-[0_0_10px_rgba(0,0,0,0.1)]'>

                            <div className='font-medium text-xl'>Sign in or create an account</div>

                            <div className='flex items-center justify-start gap-1'>
                                <div className='text-customGray'>Already have an account?</div>
                                <Button
                                    onClick={() => navigate("/user/login")}
                                    className={"text-primary"}
                                    label={"Log in"}
                                />
                            </div>

                            <div className='text-black'>Otp Code has been sent to your email.</div>

                            <TextField
                                label="OTP CODE"
                                name="otp"
                                type="text"
                            />


                            <div className='text-sm mt-8 space-y-2'>
                                <div className='text-black'>Please check your spam also.</div>
                                <div className='w-full flex justify-between items-center gap-3'>
                                    <Button
                                        className='text-sm font-medium rounded-md text-white px-6 py-3 bg-primary'
                                        type='submit'
                                        label={'GET OTP'}
                                    />
                                    <Button
                                        className={`text-sm font-medium rounded-md text-white px-6 py-3 ${isResendDisabled ? 'bg-gray-500' : 'bg-primary'}`}
                                        onClick={handleResend}
                                        disabled={isResendDisabled}
                                        label={`RESEND${isResendDisabled ? ` (${timer})` : ''}`}
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>
        </>
    )
}

export default GetOtp
