import React from 'react';

const ProfileSkeleton = () => {
    return (
        <div className='edge-spacing bg-[#E0ECF9] py-12 animate-pulse'>
            <div className='p-4 md:p-8 lg:p-12 space-y-4 bg-white md:w-[85%] m-auto rounded-md shadow-[0_0_10px_rgba(0,0,0,0.1)]'>
                <div className='flex justify-between items-center gap-4 border-b'>
                    <div className='w-1/2 h-8 bg-gray-300 rounded'></div>
                </div>

                <section className='flex flex-wrap md:flex-nowrap gap-3 justify-around md:justify-between items-start'>
                    <div className='lg:px-10'>
                        <div className='w-32 md:w-40 lg:w-60 2xl:w-72 aspect-square bg-gray-300 rounded-full'></div>
                    </div>

                    <div className='w-full md:w-[65%] md:pl-4 lg:p-0 space-y-4'>
                        <div className='max-w-96 mx-auto lg:mr-auto 2xl:m-0'>
                            <div className='w-1/3 h-4 bg-gray-300 rounded mb-2'></div>
                            <div className='w-full h-10 bg-gray-300 rounded'></div>
                        </div>
                        <div className='max-w-96 mx-auto lg:mr-auto 2xl:m-0'>
                            <div className='w-1/3 h-4 bg-gray-300 rounded mb-2'></div>
                            <div className='w-full h-10 bg-gray-300 rounded'></div>
                        </div>
                        <div className='max-w-96 mx-auto lg:mr-auto 2xl:m-0'>
                            <div className='w-1/3 h-4 bg-gray-300 rounded mb-2'></div>
                            <div className='w-full h-10 bg-gray-300 rounded'></div>
                        </div>
                        <div className='max-w-96 mx-auto lg:mr-auto 2xl:m-0'>
                            <div className='w-1/3 h-4 bg-gray-300 rounded mb-2'></div>
                            <div className='w-full h-10 bg-gray-300 rounded'></div>
                        </div>
                        <div className='max-w-96 mx-auto lg:mr-auto 2xl:m-0 flex justify-end items-center'>
                            <div className='w-24 h-10 bg-gray-300 rounded'></div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default ProfileSkeleton;
