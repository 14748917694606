import React from "react";
import { Link } from "react-router-dom";
import {
    logoImg
} from "../../assets/index";
import { scrollToTop } from '../../utils/scrollToTop'
import { FaApple } from "react-icons/fa";
import { FaGooglePlay } from "react-icons/fa";



const Footer = () => {


    const Company = [
        {
            id: 1,
            name: "About Us",
            link: "/about-us",
        },

        {
            id: 2,
            name: "Contact Us",
            link: "/contact-us",
        },
    ];

    const Support = [
        {
            id: 1,
            name: "Contact",
            link: "/contact",
        },
        {
            id: 2,
            name: "Privacy Policy",
            link: "/privacy-policies",
        },
        {
            id: 3,
            name: "Terms and Conditions",
            link: "/terms-and-conditions",
        }
    ];

    const eventsCategories = [
        {
            id: 1,
            name: "Live Concerts",
            link: "/live-concerts",
        },
        {
            id: 2,
            name: "Seminar",
            link: "/seminar",
        },
        {
            id: 3,
            name: "Festivals",
            link: "/festivals",
        },
        {
            id: 4,
            name: "Marketing",
            link: "/marketing",
        },
        {
            id: 5,
            name: "Movies",
            link: "/movies",
        },
        {
            id: 6,
            name: "Others",
            link: "/others",
        },
    ];


    return (
        <>
            <footer className="bg-white text-secondary py-10 w-full">
                <div className="flex flex-wrap gap-y-10 flex-col justify-between items-start mx-auto w-[95%]
                xl:w-[85%] md:flex-row">

                    <div className="px-3">
                        <div className="w-40 flex justify-center items-center gap-3">
                            <img src={logoImg} className="w-full h-full object-contain" alt="logo" />
                        </div>

                        <div className="mt-7 space-y-1">
                            <p className="text-black text-sm">Contact</p>
                            <p className="text-primary font-medium text-lg">1300287637</p>
                        </div>

                        <div className="mt-7 space-y-1">
                            <p className="text-black text-sm">Need live support?</p>
                            <p className="text-primary font-medium text-lg">info@olaticket.com.au</p>
                        </div>


                    </div>


                    <div className="flex flex-grow justify-start md:justify-evenly flex-wrap gap-10 px-3">

                        {/* Company */}
                        <div>
                            <h6 className="font-medium text-lg">Company</h6>

                            <div className="mt-3 md:mt-6 flex flex-col gap-2">
                                {Company.map((text) => {
                                    return (
                                        <Link key={text.id} to={text.link} onClick={scrollToTop}>
                                            <p className="hover:text-hover">
                                                {text.name}
                                            </p>
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>


                        {/* Support */}
                        <div>
                            <h6 className="font-medium text-lg">Support</h6>

                            <div className="mt-3 md:mt-6 flex flex-col gap-2">
                                {Support.map((text) => {
                                    return (
                                        <Link key={text.id} to={text.link} onClick={scrollToTop}>
                                            <p className="hover:text-hover">
                                                {text.name}
                                            </p>
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>


                        {/* Events Categories */}
                        <div>
                            <h6 className="font-medium text-lg">Events Categories</h6>

                            <div className="mt-3 md:mt-6 flex flex-col gap-2">
                                {eventsCategories.map((text) => {
                                    return (
                                        <Link key={text.id} to={text.link} onClick={scrollToTop}>
                                            <p className="hover:text-hover">
                                                {text.name}
                                            </p>
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                    </div>


                    {/* Download App */}
                    <div className="px-3 w-full md:w-fit">
                        <h6 className="font-medium text-lg">Download Our App On</h6>

                        <div className="mt-3 md:mt-6 flex flex-col gap-2 w-full">

                            <div className="group w-full p-3 rounded border">
                                <a className="w-full h-full flex items-center justify-center gap-3" href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
                                    <FaApple className="group-hover:text-hover duration-300 text-4xl" />
                                    <div>
                                        <div className="whitespace-nowrap text-[#697488]">Download on the</div>
                                        <div className="group-hover:text-hover duration-300 whitespace-nowrap font-semibold">App Store</div>
                                    </div>
                                </a>
                            </div>

                            <div className="group w-full p-3 rounded border">
                                <a className="w-full h-full flex items-center justify-center gap-3" href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
                                    <FaGooglePlay className="group-hover:text-hover duration-300 text-3xl" />
                                    <div>
                                        <div className="whitespace-nowrap text-[#697488]">Get it on</div>
                                        <div className="group-hover:text-hover duration-300 whitespace-nowrap font-semibold">Google Play</div>
                                    </div>
                                </a>
                            </div>

                        </div>

                    </div>

                </div>
            </footer>

            <footer className="w-full py-4 border-t">
                <div className="flex flex-wrap gap-3 justify-between items-center w-[95%] mx-auto px-3 xl:w-[85%]">

                    <p className="text-sm md:text-base">© 2024 OLA Ticket a division of BILI and CO Pty Ltd (ABN Number: 59 609 622 684).</p>
                    <p className="text-sm md:text-base text-[#697488]">
                        Powered By: <a href="https://ausnepit.com.au/" target='_blank' rel='noreferrer' className="hover:text-hover">AusNepIT Solutions</a>
                    </p>

                </div>
            </footer>
        </>
    );
};

export default Footer;
